import Vue from 'vue'
// Require Froala Editor js file.
import 'froala-editor/js/froala_editor.pkgd.min.js'

// require packages
import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/code_beautifier.min.js'
import 'froala-editor/js/plugins/code_view.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/js/plugins/draggable.min.js'
import 'froala-editor/js/plugins/emoticons.min.js'
import 'froala-editor/js/plugins/entities.min.js'
import 'froala-editor/js/plugins/file.min.js'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/js/plugins/help.min.js'
import 'froala-editor/js/plugins/image.min.js'
// import 'froala-editor/js/plugins/image_manager.min.js'	// x?
import 'froala-editor/js/plugins/inline_class.min.js'
import 'froala-editor/js/plugins/inline_style.min.js'
import 'froala-editor/js/plugins/line_breaker.min.js'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import 'froala-editor/js/plugins/paragraph_format.min.js'
import 'froala-editor/js/plugins/paragraph_style.min.js'
import 'froala-editor/js/plugins/quick_insert.min.js'
import 'froala-editor/js/plugins/quote.min.js'
import 'froala-editor/js/plugins/special_characters.min.js'
import 'froala-editor/js/plugins/table.min.js'
import 'froala-editor/js/plugins/video.min.js'
import 'froala-editor/js/plugins/word_paste.min.js'

// Require Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/css/themes/dark.min.css'

// require package css files
import 'froala-editor/css/plugins/code_view.min.css'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/css/plugins/draggable.min.css'
import 'froala-editor/css/plugins/emoticons.min.css'
import 'froala-editor/css/plugins/file.min.css'
import 'froala-editor/css/plugins/fullscreen.min.css'
import 'froala-editor/css/plugins/help.min.css'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/css/plugins/image_manager.min.css'
import 'froala-editor/css/plugins/line_breaker.min.css'
import 'froala-editor/css/plugins/quick_insert.min.css'
import 'froala-editor/css/plugins/special_characters.min.css'
import 'froala-editor/css/plugins/table.min.css'
import 'froala-editor/css/plugins/video.min.css'

// https://froala.com/wysiwyg-editor/examples/line-breaker/
// There are 2 options related to the line breaker:
// lineBreakerTags - the list of HTML tags between which the line-breaker should appear..
// lineBreakerOffset - the distance in pixels from the top or bottom of an element at which to show the line-breaker.

// Import and use Vue Froala lib
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)

import FroalaEditor from 'froala-editor';
window.FroalaEditor = FroalaEditor

// custom buttons (more may be included in froala-plugin-APP.js)

// FroalaEditor.DefineIcon('alertTest', {NAME: 'info', SVG_KEY: 'help'});
// FroalaEditor.RegisterCommand('alertTest', {
// 	title: 'Hello',
// 	focus: false,
// 	undo: false,
// 	refreshAfterCallback: false,
// 	callback: function () {
// 	alert('Hello!');
// 	}
// });

// FroalaEditor.DefineIcon('insertTest', {NAME: 'info', SVG_KEY: 'help'});
// FroalaEditor.RegisterCommand('insertTest', {
// 	title: 'Insert',
// 	focus: true,
// 	undo: true,
// 	refreshAfterCallback: true,
// 	callback: function () {
// 		this.html.insert('My New HTML');
// 	}
// });

// mathtype equations!!
FroalaEditor.DefineIconTemplate('insertmathtypeicon', '<i class="fas fa-square-root-variable" style="font-size:14px"></i>');	// Sparkl originally included `; color:#fff` in style
FroalaEditor.DefineIcon('insertmathtype', {NAME: 'mathtype', template:'insertmathtypeicon'});
FroalaEditor.RegisterCommand('insertmathtype', {
	title: 'Insert Equation',
	focus: true,
	undo: true,
	refreshAfterCallback: true,
	callback: function () {
		let text = this.selection.text()

		// if user selects text, just convert without opening the editor
		if (!empty(text)) {
			// remove enclosing $'s if there
			text = text.replace(/^\$(.*)\$$/, '$1')
			// console.log(text)
			this.html.insert(U.render_latex(`$${text}$`) + '&nbsp;')
			U.update_froala_model(this)

		} else {
			// for some reason, sometimes froala throws an error after the insert here, though it doesn't seem to cause any problems. by catching it we allow the math editor to show and everything seems to work
			try {
				this.html.insert('<span class="k-mathtype-placeholder">___</span>')
			} catch(e) {
				console.log('caught', e)
			}

			vapp.show_math_live_editor({original_latex: text, save_fn: rv => {
				// if not null, rv will include new_latex and new_alt_text; if new_alt_text is empty, the user has (implicitly or explicitly) decided to use the default alt text as provided by MathLive
				// currently, though, we don't tell the ML editor to show the alt text field
				let new_latex = rv?.latex || null
				// let new_alt_text = rv?.alt_text	// we're not doing anything with this at this point

				let s = this.html.get()
				// if new_latex is null (which happens if the user clicks 'cancel' from the MathLiveEditor) or empty, just remove the placeholder
				if (empty(new_latex)) {
					s = s.replace(/<span class="k-mathtype-placeholder">___<\/span>/, '')
					// note that if the user is editing a pre-existing formula, we will open the editor via another mechanism

				} else {
					// always add a space after the equation; you almost always want one anyway, and doing so helps the editing flow
					s = s.replace(/<span class="k-mathtype-placeholder">___<\/span>/, U.render_latex(`$${new_latex}$`) + '&nbsp;')
				}

				this.html.set(s)
				U.update_froala_model(this)
			}})

			// for inline editor, hide the toolbar
			if (this.opts.toolbarInline) {
				this.toolbar.hide()
			}
		}
	}
});
FroalaEditor.RegisterShortcut(187, 'insertmathtype', '', '=', false);

///////////////////////////////////////////////////////////////
/* Rules for deploying the Froala editor in CGLT apps:
	- Always deploy via FroalaWrapper
	- The Froala config object should always be created via U.get_froala_config() (in froala-plugin-appname.js), which will in turn call U.get_froala_config_cglt_standard below. This way, we have certain standard ways of doing things that are consistent across all CGLT apps, but we can customize on an app-by-app basis, and then further customize on a component-by-component basis if necessary
	- If no `config` property is supplied to FroalaWrapper, FroalaWrapper will use the default config created by U.get_froala_config
	- If a “raw” object is passed to FroalaWrapper: the provided params will be used to override or extend the default config
	- If the enclosing component needs to edit the default config, it can use get_froala_config to get the default options, then overwrite anything it needs to overwrite; FroalaWrapper won’t alter with the incoming config in this case. Use cases for this:
		- Edit the standard toolbarButtons
		- Extend the standard events (or, in rare cases, completely override the standard events) -- but override fns should call the standard fns specified here. See FreeformPartEditor in Sparkl for an example
	- Note that z_index can also be sent in via a prop; FroalaWrapper will always apply this override if z_index is sent
*/
U.get_froala_config_cglt_standard = function(params) {
	if (empty(params)) params = {}

	let config = {
		key: vapp.$store.state.froala_key,
		placeholderText: '',
		charCounterCount: false,
		attribution: false,
		quickInsertEnabled: false,
		tabSpaces: 4,	// make it so that tapping the tab key actually inserts spaces, rather than moving between input items
		enter: FroalaEditor.ENTER_P,	// tag to be used when ENTER key is hit. Possible values are FroalaEditor.ENTER_P, FroalaEditor.ENTER_DIV or FroalaEditor.ENTER_BR
		paragraphFormat: {
			N: 'Normal',
		    H1: 'Heading 1',
		    H2: 'Heading 2',
		    H3: 'Heading 3',
			BLOCKQUOTE: 'Block Quote',
		    PRE: 'Code',
		},
		paragraphFormatSelection: true,

		htmlUntouched: false,	// From froala docs: default value is false; if true, "Leave the HTML inside the editor untouched without doing any special processing to it except HTML cleaning." I'm not sure exactly what this does though; for example, the `linkAlwaysBlank` flag below continues to insert the _blank option for all links...
		// (Feb 6, 2025) even though we clean things ourselves, the processing froala does with htmlUntouched seems to crucially include changing spaces prior to </p> or <br> tags to &nbsp;'s; and without doing this, what happens is that if a user a) selects some text, then b) hits backspace, c) the space before the removed text is functionally removed. I couldn't figure out how to deal with this when htmlUntouched is true, so I turned htmlUntouched back to false

		htmlAllowedAttrs: ['accept', 'accept-charset', 'accesskey', 'action', 'align', 'allow', 'allowfullscreen', 'allowtransparency', 'alt', 'aria-.*', 'async', 'autocomplete', 'autofocus', 'autoplay', 'autosave', 'background', 'bgcolor', 'border', 'charset', 'cellpadding', 'cellspacing', 'checked', 'cite', 'class', 'color', 'cols', 'colspan', 'content', 'contenteditable', 'contextmenu', 'controls', 'coords', 'data', 'data-.*', 'datetime', 'default', 'defer', 'dir', 'dirname', 'disabled', 'download', 'draggable', 'dropzone', 'enctype', 'for', 'form', 'formaction', 'frameborder', 'headers', 'height', 'hidden', 'high', 'href', 'hreflang', 'http-equiv', 'icon', 'id', 'ismap', 'itemprop', 'keytype', 'kind', 'label', 'lang', 'language', 'list', 'loop', 'low', 'max', 'maxlength', 'media', 'method', 'min', 'mozallowfullscreen', 'multiple', 'muted', 'name', 'novalidate', 'open', 'optimum', 'pattern', 'ping', 'placeholder', 'playsinline', 'poster', 'preload', 'pubdate', 'radiogroup', 'readonly', 'rel', 'required', 'reversed', 'rows', 'rowspan', 'sandbox', 'scope', 'scoped', 'scrolling', 'seamless', 'selected', 'shape', 'size', 'sizes', 'span', 'src', 'srcdoc', 'srclang', 'srcset', 'start', 'step', 'summary', 'spellcheck', 'style', 'tabindex', 'target', 'title', 'type', 'translate', 'usemap', 'value', 'valign', 'webkitallowfullscreen', 'width', 'wrap',
			'onclick',
		],
		// we need 'span' to be listed here because otherwise, mathlive 'strut' spans are removed
		htmlAllowedEmptyTags: ['textarea', 'a', 'iframe', 'object', 'video', 'style', 'script', '.fa', '.fr-emoticon', '.fr-inner', 'path', 'line', 'hr', 'span'],

		imageInsertButtons: ['imageBack', '|', 'imageUpload', 'imageByURL'],

		imageDefaultWidth: 0,			// when first inserted, don't explicitly set the image's size
		imageResizeWithPercent: true,	// when you resize, specify size with percent instead of pixels
		imageRoundPercent: true,		// round to integer when resizing
		// we remember the last-chosen display and align properties the user has chosen
		imageDefaultDisplay: vapp.$store.state.lst.froala_image_display,
		imageDefaultAlign: vapp.$store.state.lst.froala_image_align,

		// this makes it so that if a user enters an image url manually, we just use the image url as the img src, rather than uploading the image
		imageUploadRemoteUrls: false,

		linkInsertButtons: ['linkBack'],
		linkEditButtons: ['linkOpen', 'linkEdit', 'linkRemove'],
		linkAutoPrefix: 'https://',
		linkAlwaysBlank: true,

		videoInsertButtons: ['videoBack', '|', 'videoByURL', 'videoEmbed'],
		videoEditButtons: ['videoDisplay', 'videoAlign', 'videoSize', 'videoReplace', 'videoRemove'],

		entities: '',	// this stops froala from converting unicode characters like ‘’“”'" to entities; the exceptions are [ &amp; &gt; &lt; ]
		htmlSimpleAmpersand: true,	// this stops conversion of & to &amp; -- I don't see a way to do this for <>, and we probably wouldn't want to do that anyway

		// standard events:
		events: {
			// BEFORE/AFTER a froala command btn was clicked
			'commands.before': U.froala_commands_before,
			'commands.after': U.froala_commands_after,
	
			// process images
			'image.beforeUpload': U.froala_image_before_upload_fn,
	
			// process paste events
			'paste.beforeCleanup': U.froala_paste_before_cleanup_fn,
			'paste.afterCleanup': U.froala_paste_after_cleanup_fn,
			'paste.after': U.froala_paste_after_fn,
	
			// process image resize events
			'image.resize': U.froala_image_resize_fn,
			'image.resizeEnd': U.froala_image_resize_end_fn,
	
			// add events to deal with the mathlive editor; note that if specific instances of the froalawrapper need to deal with these events, they need to call these handlers in their handlers (see FreeformPartEditor)
			'keyup': U.froala_keyup_handler,
			'keypress': U.froala_keypress_handler,
			'mouseup': U.froala_mouseup_handler,
	
			// add event to deal with inserting video links
			'video.linkError': U.froala_video_linkError_handler,
		
			// popup events: use this to discover the ids for other popups
			// config.events.initialized = function() { this.popups.onShow = (popupId) => { console.log ('Popup shown: ', popupId); } }
			'popups.show.image.edit': U.froala_show_image_edit_popup,
		},
		
		/* NOTES:
			- toolbarButtons and imageEditButtons (at least) should be specified separately for each application
		*/
	}

	return $.extend(config, params)
}

///////////////////////////////////////////////////////
// The following pattern allows us to better manipulate the froala editor functionality. to implement, we use the <froala-wrapper> tag like so:

// <froala-wrapper v-model="description" />
// <froala-wrapper :config="{}" z_index="2501" parameter="description" />
// <froala-wrapper :config="editor_config()" :parameter_object="course" parameter="description" />

// then we can reference the froala component and its surrounding component; one important thing this allows us to do is the following,
// which ensures that the modeled value will be updated after we've manipulated things in the editor
// (note that updateModel is a fn supplied by the official froala vue component)
// let fco = U.get_froala_component_from_source(editor)
// if (fco) fco.updateModel()

// this <froala-wrapper> method MUST be used for the image-insertion and paste "helpers" below to work properly

//////////////////////////////////////
// the following four fns provide a convenient way to get access to any "level" of the froala functionality:
// get_froala_editor_from_source = the base froala editor functionality, returning the `editor` object (see https://froala.com/wysiwyg-editor/docs/methods)
// get_froala_component_from_source = the froala-provided <froala> vue component
// get_froala_wrapper_from_source = our <froala-wrapper> vue component
// get_froala_wrapper_parent_from_source = the "functional" parent of the froala-wrapper component (which might *not* be get_froala_wrapper_from_source.$parent)
//
// source can be any of:
// a. the froala editor object itself (in which case we just return it)
// b. a <froala> component
// c. a <froala-wrapper> component
// d. dom object (or jquery of the dom object) that is a part of the froala-edited html (e.g. a link in the edited html)
//
// note that get_froala_editor_from_source and get_froala_component_from_source *may* work even if we're not using the <froala-wrapper> component -- see below
U.froala_get_editor_from_source = function(source) {
	if (typeof(source) == 'object') {
		// a froala editor object itself
		if (!empty(source.$tb) && !empty(source.$oel)) return source

		// a <froala> component
		if (source._isVue && !empty(source.getEditor)) return source.getEditor()

		// if we get to here, we need a froala-wrapper component for this to work -- start with get_froala_wrapper_from_source, then get the editor from there
		let wrapper = U.froala_get_wrapper_from_source(source)
		if (wrapper) return wrapper.$refs.froala_component.getEditor()
	}
	return null
}

U.froala_get_component_from_source = function(source) {
	if (typeof(source) == 'object') {
		// a <froala> component itself
		if (source._isVue && !empty(source.getEditor)) return source

		// a <froala-wrapper> component
		if (source._isVue && !empty(source.$refs) && !empty(source.$refs.froala_component)) return source.$refs.froala_component

		// if we get to here, we need a froala-wrapper component for this to work -- start with get_froala_wrapper_from_source, then get the froala component from there
		let wrapper = U.froala_get_wrapper_from_source(source)
		if (wrapper) return wrapper.$refs.froala_component
	}
	return null
}

U.froala_get_wrapper_from_source = function(source) {
	if (typeof(source) == 'object') {
		// if source is a <froala-wrapper> component itself, return it
		if (source._isVue && !empty(source.$refs) && !empty(source.$refs.froala_component)) return source

		// if source is a froala editor object or a <froala> component, get the $el from either
		if (source.$el) source = source.$el

		// now if source is a dom object -- or a jquery object from the dom object -- that is part of the froala-edited html (e.g. a link in the edited html)
		if (!empty(source.tagName)) source = $(source)	// convert dom object to jquery
		if (!empty(source.attr) && vapp.$store.state.froala_wrapper_components) {	// make sure source is now a jquery, and that we have froala_wrapper_components available
			// get the ancestor data-froala_wrapper_id and then get the wrapper component from there
			let $jq = source.closest('[data-froala_wrapper_id]')
			if ($jq.length > 0) {
				return vapp.$store.state.froala_wrapper_components[$jq.attr('data-froala_wrapper_id')]
			}
		}
	}
	return null
}

U.froala_get_wrapper_parent_from_source = function(source) {
	let wrapper = U.froala_get_wrapper_from_source(source)
	if (wrapper) return wrapper.get_parent()
	return null
}

/////////////////////////////
// This is the version currently used in cureum; we should replace with one of the above...
U.get_froala_component = function(source) {
	// source can either be a) the froala editor dom object (usually retrieved, directly or indirectly, from a froala event callback fn; see below)
	// or b) a selector referencing a dom object, or the dom object itself, that is a part of the froala-edited html (e.g. a link in the edited html)

	// the returned object will include:
	// froala_editor:  the froala editor dom object
	// froala_component: the <froala> vue component that wraps the froala_editor
	// froala_wrapper_component: the FroalaWrapper component (which in turn contains the <froala> component)
	// parent_component: the component that contains the FroalaWrapper component

	let o = {}
	if (typeof(source) == 'object' && !empty(source.$el)) {
		o.froala_wrapper_id = $(source.$el).closest('[data-froala_wrapper_id]').attr('data-froala_wrapper_id')
	} else {
		o.froala_wrapper_id = $(source).closest('[data-froala_wrapper_id]').attr('data-froala_wrapper_id')
	}

	// the FroalaWrapper component registers the froala_wrapper_id in the store (but if we haven't initialized a froala editor at all, froala_wrapper_components may not even exist)
	if (vapp.$store.state.froala_wrapper_components) {
		o.froala_wrapper_component = vapp.$store.state.froala_wrapper_components[o.froala_wrapper_id]
		if (empty(o.froala_wrapper_component)) {
			console.log('couldn’t find editor', o.froala_wrapper_id)
		} else {
			o.froala_component = o.froala_wrapper_component.$refs.froala_component
			o.parent_component = o.froala_wrapper_component.get_parent()
			o.froala_editor = o.froala_component.getEditor()
		}
	}
	// console.log(o)
	return o
}


// pass an appropriate source into this fn to ensure that the <froala> vue component immediately updates the modeled parameter to the current value specified by the froala editor
// (it may seem like this shouldn't be necessary, but sometimes it is.)
U.update_froala_model = function(source) {
	let fco = U.froala_get_component_from_source(source)
	if (fco) fco.updateModel()
	else console.error('update_froala_model: couldn’t get froala component from source')

	// cureum code:
	// let fco = U.get_froala_component(editor)
	// if (fco.froala_component) fco.froala_component.updateModel()
}

// focus in the froala editor, and set the cursor to the end of the editor.
U.froala_focus = function(source) {
	// get editor from source
	let editor = U.froala_get_editor_from_source(source)

	// set focus
	editor.events.focus()
	// move cursor to the end
	editor.selection.setAtEnd(editor.$el.get(0))
	editor.selection.restore()
}

// hide the image editor popup, and remove the "resizer"
U.froala_blur_image_editor = function(source) {
	let editor = U.froala_get_editor_from_source(source)
	if (editor) {
		editor.popups.hideAll()
		$('.fr-image-resizer.fr-active').remove()	// this might get rid of resizers in other froala editors, but we'll live with that
	} else console.error('U.froala_blur_image_editor: source could not be resolved to a froala editor instance')
}

// This seems to work to "blur" the froala editor, meaning that keyboard events won't affect it
U.froala_blur = function(source) {
	let editor = U.froala_get_editor_from_source(source)
	if (editor) editor.events.trigger('blur', [], true);
	else console.error('U.froala_blur: source could not be resolved to a froala editor instance')
}

///////////////////////////////////////////////////////

///////////////////////////////////////////////////////////
// the following three fns together form most of a custom cleanup process we use INSTEAD OF the froala editor 
// the first two are separated just in case we need to run them separately in the future, but we can just call clean_froala_pasted_text to do both
U.froala_clean_pasted_text = function(clipboard_html, line_break_tag, flags) {
	// console.warn('clean_froala_pasted_text before', clipboard_html)

	// sanitize html from pasted text
	clipboard_html = U.sanitize_html(clipboard_html)

	if (empty(line_break_tag)) line_break_tag = 'p'
	if (!flags) flags = {}

	// do the mumbo-jumbo in these fns
	let x = $(`<div>${clipboard_html}</div>`)
	x = U.froala_paste_clear_style_class(x)
	let s = U.froala_paste_clear_tags(x, line_break_tag)

	// also&nbsp;<mathtype>\sqrt{564}</mathtype>&nbsp; known&nbsp;<mathtype>4x</mathtype>&nbsp;as the
	// reconstruct mathtype; we preserved the latex in froala_paste_clear_style_class
	s = s.replace(/<mathtype>(.*?)<\/mathtype>/g, ($0, latex) => {
		// console.warn('latex: ' + latex)
		return U.render_latex(`$${latex}$`)
	})

	// if flags.remove_images is set, remove images
	if (flags.remove_images) {
		s = s.replace(/<img.*?>/g, ' ')
	}

	// some corrections for things that sometimes happen when we copy from a pdf...
	// text may come in as a single table cell...
	s = s.replace(/(<table><tbody><tr><td>)([\s\S]*?)(<\/td><\/tr><\/tbody><\/table>)/g, ($0, $1, $2, $3) => {
		// when we find this, if there aren't any internal tds, remove the table bling
		if ($2.search(/<td /) == -1) {
			return $2
		} else {
			return `${$1}${$2}${$3}`
		}
	})

	// we sometimes get p tags around/ inside of li's; remove these p's
	s = s.replace(/(<li[^>]*>)(<p[^>]*>)([\s\S]*?)(<\/p>)(<\/li>)/g, ($0, $1, $2, $3, $4, $5) => {
		if ($2.search(/<p /) == -1) {
			return `${$1}${$3}${$5}`
		} else {
			return `${$1}${$2}${$3}${$4}${$5}`
		}
	})
	s = s.replace(/<p>(<\/li>)/g, '$1')
	s = s.replace(/<p>(<(ul|ol|li)>)/g, '$1')

	// console.warn('clean_froala_pasted_text after', s)
	return s
}

U.froala_paste_clear_style_class = function(x) {
	// this preserves a small number of attributes that we want to preserve (sometimes by changing tags), but throws out all other attributes
	// we may have to do this multiple times, because the first pass might replace an outer tag that include inner tags that need to be massaged
	for (let i = 0; i < 10; ++i) {
		let html = x.html()
		x.find('*').each(function() {
			let el = $(this)

			// if the tag has a data-cglt attribute, process it specially...
			// (NOTE: I don't think this is being used now, because we catch images in beforeCleanup/afterCleanup; but it shouldn't be hurting anything)
			if (el.attr('data-cglt')) {
				// for images, preserve width/height, but not other styles
				if (this.tagName == 'IMG') {
					let w = '', h = ''
					if (el.attr('style').search(/[^a-z-](width:.*?;)/) > -1) w = RegExp.$1	// we need the tricky regex to deal with 'max-width'
					if (el.attr('style').search(/[^a-z-](height:.*?;)/) > -1) h = RegExp.$1
					el.attr('style', w+h)
				}
				return
			}

			if (el.attr('data-latex')) {
				// let latex = el.attr('data-latex')
				el.replaceWith(`<mathtype>${el.attr('data-latex')}</mathtype>`)
			}

			// add strong tags for font-weight bold and > 400
			let fw = el.css('font-weight')
			if (fw == 'bold' || fw*1 > 400) {
				if (this.tagName != 'STRONG') {
					console.log('found bold: ' + el[0].outerHTML)
					el.wrapInner('<strong></strong>')
				}
			}

			// add em tags for font-style italic
			let fs = el.css('font-style')
			if (fs == 'italic') {
				if (this.tagName != 'EM') {
					// console.log('found italic: ' + el.html())
					el.wrapInner('<em></em>')
				}
			}

			if (this.tagName == 'B') {
				// exception for QuizEditor
				if (el.hasClass('k-correct-choice')) {
					el.replaceWith(el.html())
				} else {
					// when we copy from pdf we sometimes get, e.g., <b style="font-weight:normal;"
					let fw = el.css('font-weight')
					if (fw && (fw == 'normal' || fw*1 <= 400)) {
						// console.log('fixing b')
						el.replaceWith(`<span>${el.html()}</span>`)
					} else {
						el.replaceWith('<strong>' + el.html() + '</strong>')
					}
				}
			}

			if (this.tagName == 'I') {
				el.replaceWith('<em>' + el.html() + '</em>')
			}

			// remove links that have no text, or where the text is just a nbsp
			if (this.tagName == 'A') {
				// watch out for images
				if (el.html().search(/\bimg\b/) == -1) {
					let t = el.text()
					if (empty($.trim(t))) {
						el.replaceWith(`${t}`)
					}
				}
			}
			
			// remove most ids
			el.removeAttr('id')

			// remove most style attributes
			el.removeAttr('style')

			// remove most css classes, but keep CGLT (k-) and froala (fr-) classes
			let clarr = el.attr('class')
			if (!empty(clarr)) {
				clarr = clarr.split(' ')
				let cl = ''
				for (let s of clarr) {
					if (s.search(/(k|fr)-/) === 0) {
						if (cl) cl += ' '
						cl += s
					}
				}
				// console.warn('cleaned class: ', cl, clarr)
				if (cl) el.attr('class', cl)
				else el.removeAttr('class')
			}
		})

		if (html == x.html()) break
		if (i > 0) console.log(`cycling (${i}) froala_paste_clear_style_class...`)
	}

	return x
}

U.froala_paste_clear_tags = function(x, line_break_tag) {
	// line_break_tag should be 'p' or 'br'

	// remove these tags altogether
	x.find('base,head,meta,style,title,area,map,script,canvas,noscript,del,ins').remove()

	// these too
	x.find('option,datalist,fieldset,meter,optgroup,option,output,progress,select,textarea').remove()

	// these too
	x.find('video,audio,track,embed,object,param,picture,source').remove()

	// remove all inputs, replacing with their values (this replaces Sparkl hangman queries with their text)
	x.find('input').replaceWith(function() {
		return $(this).val()
	})

	// for these tags, extract everything in them and put them directly in the dom
	x.find('body,address,article,aside,footer,header,main,nav,section').replaceWith(function() {
		return $(this).html()
	})

	// these too
	x.find('button,label,legend').replaceWith(function() {
		// but add a space around these, since they are usually inline
		return ` ${$(this).html()} `
	})

	let s = x.html()

	// preserve just the tags and attributes we want to save

	// preserve explicit <br> tags, but strip attributes if there are any
	s = s.replace(/<((\/)?(br))\b.*?>/ig, 'ZZZLTZZZ$1ZZZGTZZZ')
	
	// for these we remove all attributes
	s = s.replace(/<((\/)?(em|strong|sub|sup))\b.*?>/ig, 'ZZZLTZZZ$1ZZZGTZZZ')
	
	// tables and lists -- strip attributes for these too
	s = s.replace(/<((\/)?(table|tr|td|th|thead|tbody))\b.*?>/ig, 'ZZZLTZZZ$1ZZZGTZZZ')
	s = s.replace(/<((\/)?(ul|ol|li))\b.*?>/ig, 'ZZZLTZZZ$1ZZZGTZZZ')

	// for these we preserve all attributes (that is, attributes left from our purging above)
	s = s.replace(/<((\/)?(img|a|link|iframe|mathtype)\b.*?)>/ig, 'ZZZLTZZZ$1ZZZGTZZZ')

	// if we're using <br> as the line break, remove block-level things but add a break at the end of each of these things; this results in line breaks where we want them
	if (line_break_tag.toLowerCase() == 'br') {
		s = s.replace(/(\s*<\/(p|div|figcaption|figure|pre|blockquote|h1|h2|h3|h4|h5|h6).*?>\s*)+/ig, 'ZZZLTZZZ' + line_break_tag + 'ZZZGTZZZ')
	
	// else preserve block-level things, preserving attributes -- but convert some things to the line_break_tag (which will be p or div)
	} else {
		s = s.replace(/<((\/)?(div|figcaption|figure)\b.*?)>/ig, `ZZZLTZZZ${line_break_tag}ZZZGTZZZ`)
		s = s.replace(/<((\/)?(p|pre|blockquote|h1|h2|h3|h4|h5|h6)\b.*?)>/ig, 'ZZZLTZZZ$1ZZZGTZZZ')
	}

	// // insert line_break_tag tags at the ends of block-level things; this results in line breaks where we want them
	// s = s.replace(/(\s*<\/(p|div|figcaption|figure|pre|blockquote|h1|h2|h3|h4|h5|h6).*?>\s*)+/ig, 'ZZZLTZZZ' + line_break_tag + 'ZZZGTZZZ')

	// strip all other tags
	s = s.replace(/<(\/?)[a-z].*?>/ig, '')

	// put back the <'s we preserved
	s = s.replace(/ZZZLTZZZ/g, '<')
	s = s.replace(/ZZZGTZZZ/g, '>')

	// consolidate multiple p's/br's
	if (line_break_tag == 'p') {
		s = s.replace(/(<p>)+/g, '<p>')
	} else {
		s = s.replace(/(<br>)+/g, '<br>')
	}

	// eliminate line breaks prior to closing <td>/<th> tags
	s = s.replace(/((<(\/)?p>)|<br>)+(<\/(td|th)>)/ig, '$4')

	return s
}

// This is used to try to handle conditions where the user has gotten the cursor inside of something we don't want them editing directly
U.froala_keyup_handler = function ($evt, editor) {
	if (!editor) editor = this
	// console.log('keyup', $evt.keyCode)
	// console.log('keyup', editor.selection)
	// window.fks = editor.selection

	// if user navigates using arrow keys into a [data-froala-not-editable] or k-mathlive-span span, position before or after the span, depending on the key they tapped
	let ml_el = U.froala_selection_in_node(editor.selection, '.k-mathlive-span,[data-froala-not-editable]')
	if (ml_el) {
		// backspace or forward delete: delete the equation altogether
		if ($evt.keyCode == 8 || $evt.keyCode == 46) {
			$(ml_el).remove()
			U.update_froala_model(editor)

		// left, up arrow
		} else if ($evt.keyCode == 37 || $evt.keyCode == 38) {
			U.froala_move_cursor_out_of_el(editor.selection, ml_el, 'before')

		// right, down arrow
		} else if ($evt.keyCode == 39 || $evt.keyCode == 40) {
			if (editor.selection.endElement() == ml_el) {
				console.log('create space and move to end -- 1')
				let char_node = document.createTextNode(' ')
				$(editor.selection.endElement()).after(char_node)
				editor.selection.setAtEnd(editor.selection.endElement())
				editor.selection.restore()
			} else if (editor.selection.endElement().lastChild == ml_el) {
				console.log('create space and move to end -- 2')
				let char_node = document.createTextNode(' ')
				$(editor.selection.endElement().lastChild).after(char_node)
				editor.selection.setAtEnd(editor.selection.endElement())
				editor.selection.restore()
			} else {
				// debugger
				// froala may convert our space to a nbsp
				if (ml_el.nextSibling && (ml_el.nextSibling.textContent == ' ' || ml_el.nextSibling.textContent == ' ')) {	// if we include this clause it misses some places... (ml_el.nextSibling == editor.selection.endElement().lastChild)
					console.log('move after space')
					// this sometimes moves too far, but better than not moving far enough...
					editor.selection.setAtEnd(editor.selection.endElement())
					editor.selection.restore()
				} else if (editor.selection.endElement() == ml_el) {
					// console.log('move before new space')
					editor.selection.setAtEnd(editor.selection.endElement())
					editor.selection.restore()
				} else {
					// console.log('just move to end')
					U.froala_move_cursor_out_of_el(editor.selection, ml_el, 'after')
				}
			}
		}

		$evt.preventDefault()
		$evt.stopPropagation()
	}
}

// This is used to try to handle conditions where the user has gotten the cursor inside of something we don't want them editing directly
U.froala_keypress_handler = function ($evt, editor) {
	if (!editor) editor = this
	// console.log('keypress', $evt.keyCode)
	// console.log('keypress', editor.selection)

	// if user taps a key while inside a [data-froala-not-editable] or k-mathlive-span span, ...
	let ml_el = U.froala_selection_in_node(editor.selection, '.k-mathlive-span,[data-froala-not-editable]')
	if (ml_el) {
		let char_node = document.createTextNode(String.fromCharCode($evt.keyCode))

		// if we're at the very start of the span, move and place before the span; otherwise move and place after
		if (U.froala_at_start_of_node(editor.selection, 'k-mathlive-span,[data-froala-not-editable]')) {
			U.froala_move_cursor_out_of_el(editor.selection, ml_el, 'before')
			$(ml_el).before(char_node)
		} else {
			// console.log('keypress at end')
			U.froala_move_cursor_out_of_el(editor.selection, ml_el, 'after')
			$(ml_el).after(char_node)
		}
		editor.selection.setAfter(char_node)
		editor.selection.restore()
		U.update_froala_model(editor)

		$evt.preventDefault()
		$evt.stopPropagation()
	
	// if the last span of a <p> (or other block element) is one of our target spans...
	} else if ($(editor.selection.endElement().lastChild).is('.k-mathlive-span,[data-froala-not-editable]')) {
		// and the cursor is in that span...
		if (editor.selection.endElement().lastChild == ml_el) {
			// insert the character after the span, then put the selector after the inserted node
			let char_node = document.createTextNode(String.fromCharCode($evt.keyCode))
			$(editor.selection.endElement().lastChild).after(char_node)
			editor.selection.setAtEnd(editor.selection.endElement())
			editor.selection.restore()
			$evt.preventDefault()
			$evt.stopPropagation()
		}
	}
}

// This is used to try to handle conditions where the user has gotten the cursor inside of something we don't want them editing directly
U.froala_mouseup_handler = function ($evt, editor, custom_fn) {
	if (!editor) editor = this
	// console.log('mouseup', editor.selection)
	// window.fks = editor.selection

	// if click is entirely within the same equation, open the editor
	let ml_el = U.froala_starts_in_node(editor.selection, '.k-mathlive-span')
	if (ml_el && ml_el == U.froala_ends_in_node(editor.selection, '.k-mathlive-span')) {
		// if ml_el has 'data-not-clickable' attribute, exit now
		if (ml_el.hasAttribute('data-not-clickable')) {
			// console.log('data-not-clickable')
			return
		}
		
		let latex = $(ml_el).attr('data-latex') ?? ''
		// if this is an empty selection...
		if (editor.selection.isCollapsed()) {
			if ($(ml_el).text().length == 1) {
				// if there is only one char, either at_end or at_start will always return true, so always open the editor in this case

			// if we're at the very start or end of the equation, return (allowing the user to edit there)
			} else if (U.froala_at_end_of_node(editor.selection, '.k-mathlive-span')) {
				// console.log('at end...')
				return

			} else if (U.froala_at_start_of_node(editor.selection, 'k-mathlive-span')) {
				// console.log('at start...')
				return
			}
		}

		// if we got a custom_fn, call it
		if (!empty(custom_fn)) {
			// and include the ml_el in the fn parameters
			custom_fn(latex, ml_el)

		} else {
			// otherwise show the math editor
			vapp.show_math_live_editor({original_latex: latex, save_fn: rv => {
				// if not null, rv will include new_latex and new_alt_text; if new_alt_text is empty, the user has (implicitly or explicitly) decided to use the default alt text as provided by MathLive
				// currently, though, we don't tell the ML editor to show the alt text field
				let new_latex = rv?.latex || null
				// let new_alt_text = rv?.alt_text	// we're not doing anything with this at this point

				// if new_latex is null, user clicked cancel
				if (new_latex === null) return

				// console.log('finished editing:', new_latex)
				$(ml_el).replaceWith(U.render_latex(`$${new_latex}$`))
				U.update_froala_model(editor)
			}})
		}
	}

	// if click ends or starts inside a [data-froala-not-editable] or k-mathlive-span span, ...
	ml_el = U.froala_selection_in_node(editor.selection, '.k-mathlive-span,[data-froala-not-editable]')
	if (ml_el) {
		// ... move to after the span
		U.froala_move_cursor_out_of_el(editor.selection, ml_el, 'after')
	}
}

// When the user clicks to insert a video URL, this will get called if Froala doesn't know how to handle the url.
// Froala *does* know to handle youtube, so we don't have to worry about that here
U.froala_video_linkError_handler = function($evt, editor) {
	if (!editor) editor = this

	// $evt will be the inserted url -- e.g. `https://url.gadoe.org/wsx8`
	let url = U.get_iframeable_video_url($evt)

	let s = `<iframe src="${url}" class="k-exercise-video-iframe"></iframe>`
	editor.html.insert(s)

	// remove the error message
	$('.fr-popup').remove()
}

/////////////////////////
// the following fns are helpers for the event handlers above
U.froala_starts_in_node = function(selection, selector) {
	let jq = $(selection.element())
	if (jq.is(selector)) return jq[0] 
	jq = jq.parents(selector)
	if (jq.length > 0) return jq[0]
	return null
}

U.froala_ends_in_node = function(selection, selector) {
	let jq = $(selection.endElement())
	if (jq.is(selector)) return jq[0] 
	jq = jq.parents(selector)
	if (jq.length > 0) return jq[0]
	return null
}

// if the froala selection object starts or end within the specified span, return the span node
U.froala_selection_in_node = function(selection, selector) {
	let ml_el = U.froala_ends_in_node(selection, selector)
	if (ml_el) return ml_el

	ml_el = U.froala_starts_in_node(selection, selector)
	if (ml_el) return ml_el

	return null
}

// if the froala selection object starts or end within a mathlive span, return the mathlive span node
U.froala_mathlive_node = function(selection) {
	return U.froala_selection_in_node(selection, '.k-mathlive-span,[data-froala-not-editable]')
}

// move the text cursor before or after a given node
U.froala_move_cursor_out_of_el = function(selection, node, where) {
	if (where == 'before') selection.setBefore(node)
	else selection.setAfter(node)
	selection.restore()
}

U.froala_at_end_of_node = function(selection, selector) {
	// console.log(`froala_at_end_of_node: length=${selection.get().anchorNode.length} offset=${selection.get().anchorOffset}`)
	// if the node we're in is a text node, start by seeing if we're at the end of that text node
	let anchor_node = selection.get().anchorNode
	if (anchor_node && anchor_node.nodeType === 3) {
		// if we're not at the end of the text node, we can't be at the end of the node
		if (selection.get().anchorOffset < anchor_node.length) return false
	}

	// start with the node we're in
	let at_end = true
	let jq = $(selection.element())
	// while we haven't reached the outer mathlive node
	while (jq && !jq.is(selector)) {
		// if this node has a following sibling, we're not at the end of the equation
		let next = jq.next()
		if (next.length > 0) {
			at_end = false
			break
		}
		// otherwise go up a level
		jq = jq.parent()
	}
	return at_end
}

U.froala_at_start_of_node = function(selection, selector) {
	// if selection.get().focusOffset is not 0, we can't be at the start
	if (selection.get().focusOffset > 0) return false

	// start with the node we're in
	let at_start = true
	let jq = $(selection.element())
	// while we haven't reached the outer mathlive node
	while (jq && !jq.is(selector)) {
		// if this node has a previous non-empty sibling, we're not at the start of the equation
		let prev = jq.prev()
		if (prev.length > 0) {
			// but skip struts
			let class_name = prev.attr('class')
			if (class_name && class_name.includes('strut')) {
				// console.log('skipping strut', prev, 'text: ' + prev.text())
				jq = prev
				continue
			}
			at_start = false
			break
		}
		// otherwise go up a level
		jq = jq.parent()
	}
	return at_start && !U.froala_at_end_of_node(selection, selector)
}

// sometimes Froala doesn't call paste.beforeCleanup/paste.afterCleanup; do things we *always* have to do after a paste here.
U.froala_paste_after_fn = function() {
	// make sure that pasted images have the data-cglt attribute; this is needed to trigger them to be enlargeable
	this.$el.find('img').attr('data-cglt', 'true')
}

// usage: 'paste.beforeCleanup': U.froala_paste_before_cleanup_fn
U.froala_paste_before_cleanup_fn = function(clipboard_html, editor) {
	if (!editor) editor = this
	// we want to use our own cleanup functionality instead of Froala's, but I (PW) haven't found a way to disable froala's cleaning altogether
	// so what we do is just preserve the original clipboard_html here, then retrieve and process cglt_preserved_clipboard_html in froala_paste_after_cleanup_fn 
	// console.warn('beforeCleanup', clipboard_html)
	editor.cglt_preserved_clipboard_html = clipboard_html
	return clipboard_html
}

// usage: 'paste.afterCleanup': U.froala_paste_after_cleanup_fn
U.froala_paste_after_cleanup_fn = function(clipboard_html, editor) {
	if (!editor) editor = this
	clipboard_html = editor.cglt_preserved_clipboard_html

	// preserve and process resource links specially...
	clipboard_html = clipboard_html.replace(/<link (.*?k-lesson-component-resource-link.*?)>/g, ($0, $1) => {
		// dump the style attribute
		$1 = $1.replace(/style=".*?"/, '')
		// create a new data-resource-link-id
		$1 = $1.replace(/data-resource-link-id=".*?"/, `data-resource-link-id="${U.new_uuid()}"`)
		return `ZZZRESLINKSTARTZZZ ${$1}ZZZRESLINKENDZZZ`
	})

	// preserve and process images previously pasted via froala specially...
	clipboard_html = clipboard_html.replace(/<img (.*?class=".*?\bfr-.*?)>/g, ($0, tag) => {
		// preserve the width/height only from the style attribute
		if (tag.search(/style="(.*?)"/) > -1) {
			let style = RegExp.$1
			let w = '', h = ''
			if (style.search(/[^a-z-](width:.*?;)/) > -1) w = RegExp.$1	// we need the tricky regex to deal with 'max-width'
			if (style.search(/[^a-z-](height:.*?;)/) > -1) h = RegExp.$1
			style = w + h
			tag = tag.replace(/style="(.*?)"/, `style="${style}"`)
		}

		return `ZZZIMGSTARTZZZ ${tag}ZZZIMGENDZZZ`
	})

	// make sure any data-sparkl-queries, data-sparkl-labels, and data-sparkl-labels-img-width attributes are removed from images
	clipboard_html = clipboard_html.replace(/(data-sparkl-queries="[^"]*?")/g, '')
	clipboard_html = clipboard_html.replace(/(data-sparkl-labels="[^"]*?")/g, '')
	clipboard_html = clipboard_html.replace(/(data-sparkl-labels-img-width="[^"]*?")/g, '')
	
	// do our standard cleaning here
	let line_break_tag = editor.opts.enter == FroalaEditor.ENTER_BR ? 'br' : 'p'
	clipboard_html = U.froala_clean_pasted_text(clipboard_html, line_break_tag)

	// restore resource links
	clipboard_html = clipboard_html.replace(/ZZZRESLINKSTARTZZZ/g, '<link')
	clipboard_html = clipboard_html.replace(/ZZZRESLINKENDZZZ/g, '>')

	// restore images
	clipboard_html = clipboard_html.replace(/ZZZIMGSTARTZZZ/g, '<img')
	clipboard_html = clipboard_html.replace(/ZZZIMGENDZZZ/g, '>')

	// console.warn('froala_paste_after_cleanup_fn', clipboard_html)
	return clipboard_html
}

///////////////////////////////////////////////////////////
// fn for clearing extra empty paragraphs or breaks at the ends of froala-entered text
U.froala_trim_text = function(html) {
	// console.log('froala_trim_text (start)', html)

	// clear ` id="isPasted"`
	html = html.replace(/\s+id="isPasted"/g, '')

	// clear 'undefined' tags (this shouldn't be necessary once the froala 3.2.7 ENTER_BR bug is fixed)
	html = html.replace(/<\s*(\/?)undefined\s*>/g, '')

	html = html.replace(/^((\s|\&nbsp;)*<br>(\s|\&nbsp;)*)*([\s\S]*?)((\s|\&nbsp;)*<br>(\s|\&nbsp;)*)*$/, '$4')

	// replace singleton p's both before and after replacing empty p's with closing tags
	html = html.replace(/^((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+/, '<p>')
	html = html.replace(/((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+$/, '')

	html = html.replace(/^((\s|\&nbsp;|<br>)*<p>(\s|\&nbsp;|<br>)*<\/p>(\s|\&nbsp;|<br>)*)*([\s\S]*?)((\s|\&nbsp;|<br>)*<p>(\s|\&nbsp;|<br>)*<\/p>(\s|\&nbsp;|<br>)*)*$/, '$5')

	html = html.replace(/^((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+/, '<p>')
	html = html.replace(/((\s|\&nbsp;)*<p>(\s|\&nbsp;)*)+$/, '')

	// console.log('froala_trim_text (end)', html)

	return $.trim(html)
}

//////////////////////////
// usage: 'popups.show.image.edit': U.froala_show_image_edit_popup
U.froala_show_image_edit_popup = function() {
	// froala sometimes positions the image popup awkwardly; we could try to move it to below the image here, but my first attempt at this didn't really work
	// let popup = $('.fr-popup.fr-active')
	// console.warn('popups.show.image.edit', popup.css('top'), popup.position())
	// let ht = $('.fr-image-resizer.fr-active').height()
	// popup.css('top', `${popup.position().top + ht - 14}px`)
}

// usage: 'commands.before': U.froala_commands_before
U.froala_commands_before = function(cmd, param1, param2, editor) {
	if (!editor) editor = this
	// note: if we return false from this fn, the command will be canceled

	if (cmd == 'html') {
		if (!editor.codeView.isActive()) {
			// extract image data sources from the html, stashing them in an array
			// note that we *don't* extract srcs for images housed at their own urls
			editor.img_srcs = []
			let html = $.trim(editor.html.get())
			html = html.replace(/(<img [^>]*?src=")(data.*?)("[^>]*?>)/g, ($0, $1, $2, $3) => {
				editor.img_srcs.push($2)
				return $1 + 'XX-DATA-SRC-' + editor.img_srcs.length + '-XX' + $3
			})
			// set the html to the simplified html, allowing the data to be easily edited
			editor.html.set(html)

			// if we're not already in fullscreen mode, set to fullscreen mode
			if (!editor.fullscreen.isActive()) {
				editor.fullscreen.toggle()
				editor.toggle_fullscreen_when_done = true
			} else {
				editor.toggle_fullscreen_when_done = false
			}

			// hide the fullscreen button, because the user needs to go back out of code view to do anything else
			let wrapper = U.froala_get_wrapper_from_source(editor)
			if (wrapper) $(wrapper.$el).find('[data-cmd=fullscreen]').hide()
		}
	}
}

// usage: 'commands.after': U.froala_commands_after
U.froala_commands_after = function(cmd, param1, param2, editor) {
	if (!editor) editor = this

	// remember the last-chosen imageDisplay value, so we can use the same thing next time
	if (cmd == 'imageDisplay') {
		vapp.$store.commit('lst_set', ['froala_image_display', param1])
	
	// remember the last-chosen imageAlign value, so we can use the same thing next time
	} else if (cmd == 'imageAlign') {
		vapp.$store.commit('lst_set', ['froala_image_align', param1])

	// I would like to also remember the last-chosen imageStyle value, so we can use the same thing next time...
	} else if (cmd == 'imageStyle') {
		// but all froala tells me is the style chosen; it doesn't say whether it was applied or unapplied
		// console.log('imageStyle: ' + param1 + ' / ' + param2)
		// vapp.$store.commit('lst_set', ['froala_image_style', param1])

	} else if (cmd == 'html') {
		// when the html toolbar button is toggled OFF...
		if (!editor.codeView.isActive()) {
			// put the image sources back in
			let html = editor.codeView.get()
			html = html.replace(/(<img [^>]*?src=")XX-DATA-SRC-(.*?)-XX("[^>]*?>)/g, ($0, $1, $2, $3) => {
				return $1 + editor.img_srcs[$2 - 1] + $3
			})
			editor.html.set(html)

			// re-show the fullscreen button
			let o = U.get_froala_component(editor)
			if (o && o.froala_wrapper_component) $(o.froala_wrapper_component.$el).find('[data-cmd=fullscreen]').show()

			// and exit fullscreen mode, unless we were already in fullscreen mode
			if (editor.toggle_fullscreen_when_done) {
				if (editor.fullscreen.isActive()) editor.fullscreen.toggle()
			}
		}
	}
}

// usage: 'image.resize': U.froala_image_resize_fn
U.froala_image_resize_fn = function($img, editor) {
	if (!editor) editor = this
	// if parent has fns to deal with image resizing, call them
	let parent = U.froala_get_wrapper_parent_from_source($img)
	if (parent && parent.froala_resize_callback) parent.froala_resize_callback($img)
}

// usage: 'image.resizeEnd': U.froala_image_resize_end_fn
U.froala_image_resize_end_fn = function($img, editor) {
	if (!editor) editor = this
	// remember the last-chosen scaled image size, so we can use the same thing next time
	// if the style includes `width:(\d+)%`, save $1 as the scale
	let style = $img.attr('style')
	if (style.search(/width:\s+(\d+)%/) > -1) {
		let n = RegExp.$1 * 1
		if (!isNaN(n) && n >= 5) vapp.$store.commit('lst_set', ['froala_image_scaled_size', RegExp.$1])
	}

	// if parent has fns to deal with image resizing, call them
	let parent = U.froala_get_wrapper_parent_from_source($img)
	if (parent && parent.froala_resize_end_callback) parent.froala_resize_end_callback($img)
}

//////////////////////////
// usage: 'image.beforeUpload': U.froala_image_before_upload_fn
// note that for this to work we have to use a <froala-wrapper fn...
U.froala_image_before_upload_fn = function(images, editor) {
	if (!editor || !editor.image) editor = this

	// note that images is analogous to the file object returned by the onChange handler of a file input
	let image_file = images[0]

	if (!image_file.type.startsWith('image/')) {
		vapp.$alert('The file you specified does not appear to be an image.')
		U.froala_hide_popups(editor)		// hide the img popup/uploading popup if open
		return false
	}

	// for some reason this seems to get called for already-existing images as well as newly-pasted images
	let ei = editor.image.get()
	if (ei && (ei.attr('data-fr-image-pasted') != 'true' || ei.attr('data-cglt') == 'true')) {
		console.log('skipping already-processed image')
		U.froala_hide_popups(editor)		// hide the img popup/uploading popup if open
		return false
	}
	console.log(`processing image: ${image_file.type} / ${image_file.size}`)

	// set to last-chosen css classes
	if (ei) ei.attr('data-cglt-fix-css', 'true')
	setTimeout(U.froala_fix_image_css, 10)

	// if the file is already coded as webp and is relatively small, paste as is. we still have to pass it through the create_image_data_url fn though
	if (image_file.type == 'image/webp' && image_file.size < 40000) {
		U.create_image_data_url(image_file, {image_format: 'webp', max_width: 'full', compression_level:0.9, callback_fn: o=>{
			console.log('inserting webp < 50000 as-is; pasted image size: ' + o.img_url.length)
			// if ei is empty, insert a new img tag
			if (empty(ei) || ei.length == 0) {
				// this block is executed when we insert an image using the froala toolbar image btn
				let img_tag = `<img src="${o.img_url}" data-cglt="true" data-cglt-fix-css="true">`		//  style="width:${o.width/2}px"
				editor.html.insert(img_tag)
				setTimeout(U.froala_fix_image_css, 10)

			// else insert the dataURL as the src tag
			} else {
				// this block is executed when we copy/paste an image
				// ei.attr('style', `width:${o.width/2}px`)
				ei.attr('src', o.img_url)
				ei.attr('data-cglt', 'true')
				ei.attr('data-cglt-fix-css', 'true')
				ei.removeAttr('data-fr-image-pasted')
				setTimeout(U.froala_fix_image_css, 10)
			}

			// hide the img popup/uploading popup if open
			U.froala_hide_popups(editor)

			U.update_froala_model(editor)
		}})
		
	} else {
		vapp.$prompt({
			title: 'Choose Image Size',
			text: 'Choose an image size for your pasted image. Please note:<ul class="my-2"><li>Smaller images load faster for users, so please choose the smallest size appropriate for your needs.</li><li class="mt-1">After this step, you can drag-and-drop the image corners to set how big the image should appear on the page.</li></ul>',
			promptType: 'select',
			// allowing for full-size images is too dangerous -- files could be *huge*; to allow it, add {value:'full', text: 'Full-Size'}
			selectOptions: [{value:'360', text: 'Small'}, {value:'540', text: 'Medium'}, {value:'800', text: 'Large'}, {value:'1200', text: 'X-Large'}, {value:'1600', text: 'Max'}],
			initialValue: vapp.$store.state.lst.froala_image_size,
			acceptText: 'Select',
			focusBtn: true,		// focus on the accept btn when dialog is rendered
		}).then(size => {
			// deal with users that might have previously saved with 'full' option
			if (size == 'full') size = '1600'
			vapp.$store.commit('lst_set', ['froala_image_size', size])

			// adjust compression level based on size as follows
			let cl = 0.9	// 0.9 for 800 and less
			if (size == 1200) cl = 0.8	// then go down to 0.8 for 1200 (X-Large)
			if (size == 1600) cl = 0.7	// and 0.7 for 1600 (Max)

			// use the create_image_data_url utility fn to convert the image file to a dataURL
			// typical file sizes for given max_width's: 500 (56815) - 600 (77939) - 668 (93107)
			U.create_image_data_url(image_file, {image_format: 'webp', max_width: size, compression_level:cl, callback_fn: o=>{
				// use the returned img_url (dataURL) as the src for the img tag
				console.log('pasted image size: ' + o.img_url.length)
				vapp.$inform('Pasted image size: ' + U.format_bytes(o.img_url.length))

				// console.log('callback', ei, o.img_url)
				// if ei is empty, insert a new img tag
				if (empty(ei) || ei.length == 0) {
					// this block is executed when we insert an image using the froala toolbar image btn
					let img_tag = `<img src="${o.img_url}" data-cglt="true" data-cglt-fix-css="true">`		//  style="width:${o.width/2}px"
					editor.html.insert(img_tag)
					setTimeout(U.froala_fix_image_css, 10)

				// else insert the dataURL as the src tag
				} else {
					// this block is executed when we copy/paste an image
					// ei.attr('style', `width:${o.width/2}px`)
					ei.attr('src', o.img_url)
					ei.attr('data-cglt', 'true')
					ei.attr('data-cglt-fix-css', 'true')
					ei.removeAttr('data-fr-image-pasted')
					setTimeout(U.froala_fix_image_css, 10)
				}

				U.froala_hide_popups(editor)		// hide the img popup/uploading popup if open

				U.update_froala_model(editor)
			}})
		}).catch(n=>{
			// if user cancels, remove the image
			ei.remove()
		}).finally(f=>{})

		// we have to make this dialog's z-index really high in case we're in full-screen mode, because froala sets the full-screen editor ridiculously high
		setTimeout(x=>{
			// do this for the FIRST overlay, and the LAST simple-dialog -- that will target the dialog we just opened.
			$('.v-overlay--active').first().css('z-index', '92147483642')
			$('.simple-dialog').last().parents('.v-dialog__content').css('z-index', '92147483643')
		})
	}

	// hide the img popup/uploading popup if open
	U.froala_hide_popups(editor)

	return false
}

// hackish things to make pasted image appear the way we want
U.froala_fix_image_css = function() {
	let jq = $('[data-cglt-fix-css]')
	// console.warn('froala_fix_image_css', jq.length)

	// hide the image-resizer div (the square that lets you resize the image), since you can't use it until we've finished the paste
	$('.fr-image-resizer.fr-active').remove()

	jq.each(function(index) {
		// Froala image classes:
		// fr-dib == block; fr-dii = inline
		// fr-fil == left-align; fr-fir = right-align; neither for center align
		// add fr-draggable to make it draggable(?)

		// set image width to the last-saved % width, if we have a valid value for froala_image_scaled_size (see FroalaWrapper)
		if (vapp.$store.state.lst.froala_image_scaled_size > 3) {
			$(this).css('width', vapp.$store.state.lst.froala_image_scaled_size + '%')
		}

		// set classes to the last-chosen values
		let cls = $(this).attr('class') || ''
		// first remove all the froala classes
		cls = cls.replace(/(fr-dib|fr-dii|fr-fic|fr-fil|fr-fir|fr-draggable)/g, '')
		// fr-fic seems to always be added by froala, even though it doesn't do anything
		cls += ' fr-fic'
		// add block or inline
		cls += (vapp.$store.state.lst.froala_image_display == 'block') ? ' fr-dib' : ' fr-dii'
		/// add left or right if align isn't center
		if (vapp.$store.state.lst.froala_image_align != 'center' && vapp.$store.state.lst.froala_image_align) cls += ` fr-fi${vapp.$store.state.lst.froala_image_align[0]}`
		// add draggable
		cls += ' fr-draggable'
		cls = $.trim(cls)
		// console.log(cls)
		$(this).attr('class', cls)

		// remove the fix-css attribute, so we don't do this again
		// console.log('remove data-cglt-fix-css')
		$(this).removeAttr('data-cglt-fix-css')
	})
}

U.froala_hide_popups = function(editor) {
	// hide any froala popups (e.g. the image editor/uploading popup) if open
	editor.popups.hideAll()
	setTimeout(x=>editor.popups.hideAll(), 10)
	setTimeout(x=>editor.popups.hideAll(), 100)					
}

// sanitize html, including cruft that comes in when we paste from word (and possibly also other sources)
U.sanitize_html = function(html) {
	// console.log('sanitize before: ', html)
	let sanitize_inner = function(jq) {
		jq.contents().each(function() { 
			// console.log(this)
			// remove comments (Node type 8 corresponds to comments)
			if (this.nodeType === 8) { $(this).remove(); return; }

			// remove script, style, meta, and base tags
			if (['SCRIPT', 'STYLE', 'META', 'BASE'].includes(this.tagName)) { $(this).remove(); return; }

			// remove link tags unless they are our special lesson-component-resource-links
			if (this.tagName === 'LINK') {
				let cl = this.getAttribute('class')
				if (!cl || !cl.includes('k-lesson-component-resource-link')) { $(this).remove(); return; }
			}

			// recursively check child elements
			if (this.nodeType === 1) sanitize_inner($(this))
		})
	}

	let jqx = $(`<div>${html}</div>`)
	sanitize_inner(jqx)
	// console.log('sanitize after: ', jqx.html())
	return jqx.html()
}
