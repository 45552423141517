<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div class="k-froala-wrapper-outer">
	<froala :class="css_class" :config="config_x" :data-froala_wrapper_id="froala_wrapper_id" ref="froala_component" v-model="model_value"></froala>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	name : "FroalaWrapper",
	// components: { TemplateComponent },
	props: {
		value: { required: false, default() { return null } },
		css_class: { required: false, default() { return '' } },
		parameter: { required: false, default() { return '' } },
		parameter_object: { required: false, default() { return null } },
		config: { required: false, default() { return null } },
		z_index: { required: false, default() { return null } },
		parent_component: { required: false, default() { return null } },
	},
	data() { return {
		config_x: {},
		froala_wrapper_id: U.new_uuid(),
		model_value: '',
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		// return the froala editor, so you can do, e.g., editor.html.set()
		editor() {
			return this.$refs.froala_component.getEditor()
		},
	},
	watch: {
		// this might seem a little hackish, but we have to use this watcher to send updated html to the component includer, so that we can handle math equations properly.
		model_value(val) {
			// console.log('B', val)
			if (empty(val)) val = ''	// make sure val is a string

			// replace mathlive spans with latex
			if (val.includes('k-mathlive-span')) {
				let jq = $(`<div>${val}</div>`)
				// but *don't* replace if the span has a 'data-froala-no-mathlive-replace' attribute (this allows us, e.g., to use these spans for numeric/stem queries)
				jq.find('.k-mathlive-span:not([data-froala-no-mathlive-replace])').replaceWith(function(){return '$' + $(this).attr('data-latex') + '$'})

				val = jq.html()
			}

			// remove empty spans (Froala would by default do this for us, but if we let it do it, it screws up "struts" in mathlive
			val = val.replace(/<span[^>]*><\/span>/g, '')

			// sanitize html (we do this in froala-plugin too, but we want to make sure)
			val = U.sanitize_html(val)

			// console.log('updating model_value', val)

			// we provide multiple options for mapping the model_value returned by the froala editor onto the parent's parameter...
			if (this.parameter_object) {
				this.parameter_object[this.parameter] = val

			} else if (this.parameter) {
				this.$parent[this.parameter] = val

			} else {
				this.$emit('input', val)
				// this.$emit('update:value', val)	// for vue 3, we will need to switch to this, and use "modelValue" instead of "value"
				// https://vuejs.org/guide/components/events.html#usage-with-v-model
			}
		},
	},
	created() {
		// if we're passed an explicit froala config object, use it; otherwise use the default supplied by U.get_froala_config
		let config

		// if this.config is empty, use the default U.get_froala_config as-is
		if (!this.config) config = U.get_froala_config({})
		// else if this.config has a "key" value specified, it's an already "fully-formed" config, so use it as is
		else if (this.config.key) config = this.config
		// else pass the supplied config values through to get_froala_config
		else config = U.get_froala_config(this.config)

		// if we got a z_index value, add it to config
		if (this.z_index !== null) config.zIndex = this.z_index
		
		this.config_x = config

		// create the froala_wrapper_components object in the store if not already there
		if (!this.$store.state.froala_wrapper_components) {
			this.$store.commit('set', ['froala_wrapper_components', {}])
		}

		// then "register" this wrapper component
		this.$store.commit('set', ['froala_wrapper_components', this.froala_wrapper_id, this])

		// get initial model value; we provide multiple options for mapping the model_value returned by the froala editor onto the parent's parameter...
		this.set_model_value()

		vapp.froala_wrapper_component = this

		/* ***************************************
		NOTE!!!

		If the including component needs to push a change to the value being edited in the editor, it must explicitly call set_model_value after setting the "value" param being passed in to the FroalaWrapper component (probably via v-model),
		and also after waiting a tick for the value param to filter through to the wrapper. So if the wrapper component is referenced via this.$refs.froala_wrapper, the call would be:

		this.$nextTick(x=>this.$refs.froala_wrapper.set_model_value())
		*************************************** */
	},
	mounted() {
	},
	methods: {
		set_model_value() {
			// we provide multiple options for mapping the model_value returned by the froala editor onto the parent's parameter...
			let s
			// if a parameter_object is passed in, we directly get/set parameter_object[this.parameter]
			if (this.parameter_object) {
				s = this.parameter_object[this.parameter]

			} else if (this.parameter) {
				// if we get a parameter (but not a parameter_object), set this.$parent.parameter
				// note that this requires the froala-wrapper component to be a *direct child* of the component it's in
				s = this.$parent[this.parameter]
				// (we might want to use this if we need access to the parameter name for some other reason...)

			} else {
				// if parameter/parameter_object not supplied, use the standard vue v-model method
				// https://v2.vuejs.org/v2/guide/components-custom-events.html
				// https://www.digitalocean.com/community/tutorials/how-to-add-v-model-support-to-custom-vue-js-components
				s = this.value
			}

			this.model_value = U.render_latex(s)
			// console.log('setting model_value: ', this.model_value)
		},

		get_parent() {
			if (this.parent_component) return this.parent_component
			else return this.$parent
		},

		focus() {
			// console.log('in froala wrapper focus')
			this.editor.events.focus()
		},
	}
}
</script>

<style lang="scss">
.k-froala-wrapper-outer {
	// let the user know that equations are clickable to edit
	.k-mathlive-span, .ML__base {
		cursor:pointer;
	}
}

// limit width of editable area in fullscreen mode
.k-froala-wrapper-outer.fr-fullscreen-wrapper {
	.fr-wrapper {
		background-color:#999!important;
	}
	.fr-element {
		background-color:#fff;
		max-width:840px;
		margin-left:auto;
		margin-right:auto;
		border-left:1px solid #000;
		border-right:1px solid #000;
		border-bottom:1px solid #000;
	}
}

// redefine fr-shadow class, and make 'k-help-img' an alias of this class
.k-help-img, .fr-shadow {
	// transform: scale(0.5) translate(-1,-1);
	max-width:100%;
	border:1px solid #888;
	border-radius:4px;
	box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)!important;
	padding:0!important
	// image-resolution: 72dpi
}

// the below are used for legacy help images; probably only needed in Satchel
.k-help-img.float-right {
	margin-left:8px;
	margin-bottom:6px;
}

.k-help-img.float-left {
	margin-right:8px;
	margin-bottom:6px;
}

.k-help-img.block {
	display:block;
	margin:4px auto;
}
</style>
