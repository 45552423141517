<template><div class="k-mini-nav">
	<v-tooltip bottom><template v-slot:activator="{on}"><div class="k-mini-nav-btn-outer"><div class="k-mini-nav-btn-current-indicator" v-visible="false"><v-icon color="primary">fas fa-right</v-icon></div><v-btn v-on="on" fab x-small class="k-mini-nav-non-current-btn elevation-0" @click="go_to_home('home', $event)"><v-icon>{{ home_tab.icon }}</v-icon></v-btn></div></template>{{ home_tab.title }}</v-tooltip>
	<v-tooltip bottom><template v-slot:activator="{on}"><div class="k-mini-nav-btn-outer" v-show="tab_enabled('classes')"><div class="k-mini-nav-btn-current-indicator" v-visible="mininav_tab_showing('classes')"><v-icon color="primary">fas fa-right</v-icon></div><v-btn v-on="on" fab x-small :class="(!mininav_tab_showing('classes'))?'k-mini-nav-non-current-btn elevation-0':''" @click="go_to_home('classes', $event)"><v-icon small>{{ classes_tab.icon }}</v-icon></v-btn></div></template>{{ classes_tab.title }}</v-tooltip>
	<v-tooltip bottom><template v-slot:activator="{on}"><div class="k-mini-nav-btn-outer" v-show="tab_enabled('resourcerepos')"><div class="k-mini-nav-btn-current-indicator" v-visible="mininav_tab_showing('resourcerepos')"><v-icon color="primary">fas fa-right</v-icon></div><v-btn v-on="on" fab x-small :class="(!mininav_tab_showing('resourcerepos'))?'k-mini-nav-non-current-btn elevation-0':''" @click="go_to_home('resourcerepos', $event)"><v-icon small>{{ resourcerepos_tab.icon }}</v-icon></v-btn></div></template>{{ resourcerepos_tab.title }}</v-tooltip>
	<v-tooltip bottom><template v-slot:activator="{on}"><div class="k-mini-nav-btn-outer" v-show="tab_enabled('mycollections')"><div class="k-mini-nav-btn-current-indicator" v-visible="mininav_tab_showing('mycollections')"><v-icon color="primary">fas fa-right</v-icon></div><v-btn v-on="on" fab x-small :class="(!mininav_tab_showing('mycollections'))?'k-mini-nav-non-current-btn elevation-0':''" @click="go_to_home('mycollections', $event)"><v-icon small>{{ mycollections_tab.icon }}</v-icon></v-btn></div></template>{{ mycollections_tab.title }}</v-tooltip>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
// import TemplateComponent from '@/components/TemplateComponent'

export default {
	// components: { TemplateComponent },
	props: {
		// current_section will indicate the *type* of *collection* that is currently showing
		current_section: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
	}},
	computed: {
		...mapState(['site_config', 'user_info']),
		...mapGetters(['signed_in', 'studentish_role']),
		home_tab() {
			const { home: customized_home = {} } = this.site_config.customized_tabs
			const icon = customized_home.icon || 'fas fa-home'
			const title = customized_home.title || 'Site Home'
			return { icon, title }
		},
		classes_tab() {
			const { classes: customized_classes = {} } = this.site_config.customized_tabs
			const icon = customized_classes.icon || 'fas fa-chalkboard'
			const title = customized_classes.title || 'Courses Home'
			return { icon, title }
		},
		resourcerepos_tab() {
			const { resourcerepos: customized_resourcerepos = {} } = this.site_config.customized_tabs
			const icon = customized_resourcerepos.icon || 'fas fa-diagram-project'
			const title = customized_resourcerepos.title || `${this.site_config.resourcerepos_noun} Home`
			return { icon, title }
		},
		mycollections_tab() {
			const { mycollections: customized_mycollections = {} } = this.site_config.customized_tabs
			const icon = customized_mycollections.icon || 'fas fa-cubes-stacked'
			const title = customized_mycollections.title || 'My Content Collections'
			return { icon, title }
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		// note that tab_enabled here mostly mirrors what we do in WelcomeView
		tab_enabled(tab_id) {
			// some collection types are never viewable to users that aren't signed in, or to studentish users
			if (tab_id == 'pd' && (!this.signed_in || this.studentish_role)) return false
			if (tab_id == 'mycollections') {
				// for mycollections, we have a show_mycollections_when_not_signed_in flag; if yes, show the tab when not signed in
				if (this.site_config.show_mycollections_when_not_signed_in == 'yes' && !this.signed_in) return true
				// else don't show the tab if not signed in
				if (!this.signed_in || this.studentish_role) return false
			}
			// if main_tabs_to_include is 'all', include everything at this point
			if (this.site_config.main_tabs_to_include == 'all') return true
			// otherwise honor the array stored in main_tabs_to_include
			return this.site_config.main_tabs_to_include.includes(tab_id)
		},
		mininav_tab_showing(tab_id) {
			if (tab_id == 'resourcerepos') return (this.current_section == 'repo' || this.current_section == 'pd')
			if (tab_id == 'classes') return (this.current_section == 'course')
			if (tab_id == 'mycollections') return (this.current_section == 'my')
			return false	// shouldn't happen
		},
		go_to_home(new_section, event) {
			vapp.go_to_home(new_section, event)
		},
	}
}
</script>

<style lang="scss">
.k-mini-nav {
	margin-right:0px;
	display:flex;
	flex-direction: column;
	justify-content: center;
	background-color:rgba(255, 255, 255, 0.85);
	height:160px;
	border-radius:16px 0 0 16px;
	padding:12px 24px 12px 6px;

	.k-mini-nav-btn-outer {
		display:flex;
		align-items:center;
	}
	.k-mini-nav-btn-current-indicator {
		.fas {
			font-size:16px;
		}
		margin-right:4px;
	}

	.v-btn {
		margin:2px -16px 2px 0;
		// color:#444;
		// i { color:#fff!important; }
		border:1px solid #000;
		// background-color:#666;
		// box-shadow: none!important;
	}

	.k-mini-nav-home-btn {
		border-color:transparent;
		background-color:transparent!important;
		.fas, .far {
			font-size:22px!important;
		}
	}
	
	.k-mini-nav-non-current-btn {
		border-color:transparent;
		background-color:#fff!important;
		i { color:#fff!important; }
	}
}

.k-mini-nav-site-section-heading {
	text-align:center;
	font-weight:900;
	font-size:16px;
	line-height:16px;
	color:#333;
	text-transform: uppercase;
	margin:12px 0 -2px 0;
}
</style>
