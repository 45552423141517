<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<!-- we don't actually want to show anything here; we just show the sparkl embed frame -->
	<div v-visible="false" style="margin:32px auto 32px auto; max-width:420px; background-color:#fff; padding:12px; border-radius:8px;">
		 {{site_config.sparkl_app_name}} Bank {{ sparkl_bank_activity_id }} should appear here...
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	props: {
		sparkl_bank_activity_id: { type: String, required: true },
	},
	data() { return {
	}},
	computed: {
		...mapState(['user_info', 'site_config']),
		...mapGetters(['signed_in', 'my_default_collection']),
	},
	created() {
		vapp.sparkl_bank_standalone_view = this

		// user must be signed in to view a bank
		if (this.signed_in) {
			this.initialize_standalone_sparkl_bank()
		} else {
			// if viewing on localhost, go to sign-in page
			if (window.location.origin.includes('localhost')) {
				vapp.app_mode = 'login'

			} else {
				vapp.redirect_to_login()
			}
		}
	},
	mounted() {
	},
	methods: {
		initialize_standalone_sparkl_bank() {
			// log resource usage; see ResourceCollectionItem
			let uemail = (this.signed_in) ? this.user_info.email : 'unsignedin@cglt.com'
			let uid    = (this.signed_in) ? this.user_info.user_id : 3859
			let school = (this.signed_in && this.user_info.district_department) ? this.user_info.district_department.join(',') : ''

			this.$store.dispatch('log_resource_usage', {
				'sparkl_activity_id': this.sparkl_bank_activity_id,
				'action': 'view_sparkl_bank',
				'user_id': uid,
				'user_email': uemail,
				'system_role': this.user_info.system_role,
				'school': school,
				// course_code is added by the dispatch fn
			}).then((result) => {
				let resource = new Resource(result.resource_data)
				console.warn(resource)

				// debug: override sparkl_origin
				let sparkl_origin_override = null
				if (['1265', '1323'].includes(this.sparkl_bank_activity_id)) {
					sparkl_origin_override = 'localhost'
				}

				vapp.$refs.sparkl_embed.show_activity({
					resource_type: 'sparkl_bank',
					activity_record: {
						tool_activity_id: this.sparkl_bank_activity_id,
						lti_resource_link_id: resource.resource_id,
						activity_title: resource.description,
						creator_user_id: resource.creator,
					},
					force_reload: false,
					embed_mode: 'view',

					// don't allow editing in standalone mode
					force_prevent_original_to_be_edited: 'no',

					show_close_btn: false,
					show_copy_for_my_use_btn: false,
					viewing_original_of_in_my_collections: false,
					controller_component: this,
					sparkl_origin_override: sparkl_origin_override,
				})
			})

		},

		//////////////////////////////
		// FUNCTIONS ADAPTED FROM ResourceCollectionItem THAT ARE CALLBACKS FROM THE SPARKL IFRAME:

		// this is called from a sparkl_bank resource when the user creates a new activity from the bank
		activity_created_from_bank(data) {
			console.log('activity_created_from_bank', data)
			// create a resource from the data, which should include: sparkl_activity_id, activity_instructions, activity_title, activity_editors, case_alignments
			let r = new Resource({
				resource_id: 'new',
				type: 'sparkl',
				url: data.sparkl_activity_id,
				description: data.activity_title,
				long_description: `Student activity with ${data.stars_available} star${data.stars_available == 1 ? '' : 's'} available to be earned`,
				stars_available: data.stars_available,
				standards: data.case_alignments,
				creator: this.user_info.user_id,
			})

			// save the resource
			this.$store.dispatch('save_resource', {resource: r}).then(saved_resource_data=>{
				// in standalone mode we won't be showing the user's sandbox, but we should still have my_default_collection, so the below should still work

				// insert the saved resource in the sparkl unit (1) of the user's sandbox
				let saved_resource = new Resource(saved_resource_data)
				this.$store.commit('set', [this.my_default_collection.units[1].resources, 'PUSH', saved_resource])

				// then dispatch save_default_collection_resources; note that it will add the resource to my_resources and take care of my_ca_mappings
				this.$store.dispatch('save_default_collection_resources').then(x=>{
					console.log('save_default_collection_resources completed!')
				})
			})

		},

		sparkl_activity_saved(activity_data_from_sparkl) {
			// console.warn('activity_data_from_sparkl', activity_data_from_sparkl)
			// The user will only be able to edit the bank activity if they happen to be the creator/editor of the bank activity, which is unlikely
			// if this does happen and the user edits from here, changes won't get recorded in Inspire

			// this.$alert(`Note that changes to the bank title will not be recorded in ${this.site_config.app_name} when editing from the standalone view.`)
			return
		},

	},
}
</script>

<style lang="scss">
</style>
