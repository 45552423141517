<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div class="k-main-collection k-home-home-wrapper">
	<h2 class="k-page-title d-flex">
		<v-icon large color="primary" class="mr-4" style="margin-top:-2px">fas fa-home</v-icon>
		<b>Home</b>
		<v-spacer/>
	</h2>

	<div class="k-home-home-wrapper-inner">
		<div class="fr-view" v-html="home_page_content"></div>

		<div v-if="oidc_info" class="mt-3 pt-2 mb-1 fr-view" style="border-top:1px solid #ccc; font-size:16px;" v-html="oidc_info"></div>
		
		<div v-if="!signed_in && site_config.show_banner_signin_btn == 'no' && !backdoor_login_only" class="text-center mt-4 mb-2 fr-view">
			<v-btn color="primary" @click="sign_in">
				Sign In to {{ site_config.app_name }}
				<v-icon small class="ml-2">fas fa-sign-in</v-icon>
			</v-btn>
		</div>
	</div>
	<!-- site_footer_html can be set in config file -->
	<!-- <div v-if="site_config.site_footer_html" v-html="site_config.site_footer_html"></div> -->
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	computed: {
		...mapState(['user_info', 'site_config']),

		...mapGetters(['signed_in', 'role', 'backdoor_login_only']),

		home_page_variant() {
			// if we only have one variant, return it
			if (this.site_config.home_page_variants.length == 1) {
				return this.site_config.home_page_variants[0]
			}

			// if not signed in and we have an unsignedin variant, return it
			if (!this.signed_in && this.site_config.home_page_variants.includes('unsignedin')) return 'unsignedin'

			// else try to return varient matching role, but if we don't have a student or parent varient, just return 'staff', which will serve for 'signedin'
			if (this.role == 'student' && this.site_config.home_page_variants.includes('student')) return 'student'
			if (this.role == 'parent' && this.site_config.home_page_variants.includes('parent')) return 'parent'	// in this case you should be viewing FamilyHome...
			return 'staff'
		},

		home_page_content() {
			this.$store.dispatch('get_home_page_content', this.home_page_variant)
			return this.$store.state.home_page_content[this.home_page_variant]
		},

		oidc_info() {
			let s = ''
			if (this.user_info.first_name) s += sr('<div>You are signed in as $1 $2 ($3)</div>', this.user_info.first_name, this.user_info.last_name, this.user_info.email)
			
			if (this.site_config.show_user_system_data_on_home == 'yes') {
				s += '<ul class="ml-4">'
				if (this.user_info.oidc_data?.system_name) s += sr('<li>School system: $1</li>', this.user_info.oidc_data.system_name)
				if (this.user_info.oidc_data?.school_name) s += sr('<li>School: $1</li>', this.user_info.oidc_data.school_name)
				if (this.user_info.oidc_data?.user_role) s += sr('<li>Role: $1</li>', this.user_info.oidc_data.user_role)
				if (this.user_info.first_name) s += '</ul>'
			}
			return s
		}
	},
	
	methods: {
		sign_in($evt) {
			// if user holds down shift and cmd (mac, prob. alt on windows), always show native cureum login; this allows, e.g., for us to sign in using the magic password to Inspire
			if ($evt && $evt.shiftKey && $evt.metaKey) {
				vapp.app_mode = 'login'

			} else {
				vapp.redirect_to_login()
			}
		},
	}
}
</script>

<style lang="scss">
.k-home-home-wrapper {
	min-height:300px;
}

.k-home-home-wrapper-inner {
	padding: 0 16px;
	
	.fr-view {
		font-size:18px;
		line-height:1.4em;
		max-width:920px;
		margin: 0 auto;
		text-align:left;

		h2 {
			font-size:27px;
			line-height:33px;
		}
		h3 {
			font-size:22px;
			line-height:27px;
		}
	}
}
</style>
