<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div>
<div class="k-main-division-wrapper k-main-welcome">
	<div class="d-flex align-center mb-10">
		<v-spacer/>
		<div class="k-main-welcome__question k-shadow-text mr-8"><b>{{welcome_msg}}</b></div>
		<div class="k-main-welcome__time-date k-shadow-text" v-html="time_string"></div>
		<v-spacer/>
	</div>

	<div class="k-main-welcome--nav-btns">
		<template v-for="tab_code in site_config.main_tabs_to_include">
			<v-tooltip bottom><template v-if="tab_code=='home'" v-slot:activator="{on}"><v-btn v-show="tab_showing('home')" class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('home')" :class="welcome_section_showing=='home' || welcome_section_showing=='family_home'?'k-main-welcome--nav-btn-current':''"><v-icon>{{ home_tab.icon }}</v-icon></v-btn></template>{{ home_tab.title }}</v-tooltip>
			<v-tooltip bottom><template v-if="tab_code=='classes'" v-slot:activator="{on}"><v-btn v-show="tab_showing('classes')" class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('classes')" :class="welcome_section_showing=='classes'?'k-main-welcome--nav-btn-current':''"><v-icon>{{ classes_tab.icon }}</v-icon></v-btn></template>{{ classes_tab.title }}</v-tooltip>
			<v-tooltip bottom><template v-if="tab_code=='resourcerepos'" v-slot:activator="{on}"><v-btn v-show="tab_showing('resourcerepos')" class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('resourcerepos')" :class="welcome_section_showing=='resourcerepos'?'k-main-welcome--nav-btn-current':''"><v-icon>{{ resourcerepos_tab.icon }}</v-icon></v-btn></template>{{ resourcerepos_tab.title }}</v-tooltip>
			<v-tooltip bottom><template v-if="tab_code=='pd'" v-slot:activator="{on}"><v-btn v-show="tab_showing('pd')" class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('pd')" :class="welcome_section_showing=='pd'?'k-main-welcome--nav-btn-current':''"><v-icon>{{ pd_tab.icon }}</v-icon></v-btn></template>{{ pd_tab.title }}</v-tooltip>
			<v-tooltip bottom><template v-if="tab_code=='mycollections'" v-slot:activator="{on}"><v-btn v-show="tab_showing('mycollections')" class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('mycollections')" :class="welcome_section_showing=='mycollections'?'k-main-welcome--nav-btn-current':''"><v-icon>{{ mycollections_tab.icon }}</v-icon></v-btn></template>{{ mycollections_tab.title }}</v-tooltip>
			<v-tooltip bottom><template v-if="tab_code=='standards'" v-slot:activator="{on}"><v-btn v-show="tab_showing('standards')" class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('standards')" :class="welcome_section_showing=='standards'?'k-main-welcome--nav-btn-current':''"><v-icon>{{ standards_tab.icon }}</v-icon></v-btn></template>{{ standards_tab.title }}</v-tooltip>
		</template>

		<v-tooltip bottom><template v-slot:activator="{on}"><v-btn v-show="!$vuetify.breakpoint.xs&&!$vuetify.breakpoint.sm&&role=='admin'" class="k-main-welcome--nav-btn" v-on="on" @click="switch_welcome_section('admin')" :class="welcome_section_showing=='admin'?'k-main-welcome--nav-btn-current':''"><v-icon>{{ admin_tab.icon }}</v-icon></v-btn></template>{{ admin_tab.title }}</v-tooltip>
	</div>

	<div class="k-main-welcome--pages">
		<HomeHome v-if="welcome_section_showing=='home'" />
		<!-- <StandardsHome v-else-if="welcome_section_showing=='standards'" /> -->
		<AdminList v-else-if="welcome_section_showing=='admin'" />
		<FamilyHome v-else-if="welcome_section_showing=='family_home'" />
		<ResourceUsage v-else-if="welcome_section_showing=='resource_usage'"/>
		<div v-else>
			<FavoritesIndex v-if="index_view_flavor=='favorites'" />
			<div v-else>
				<CourseIndex ref="lp_index_component" v-if="welcome_section_showing=='classes'" />
				<RepositoriesIndex v-if="welcome_section_showing=='resourcerepos'" />
				<PDIndex v-if="welcome_section_showing=='pd'" />
				<MyCollectionsIndex v-if="welcome_section_showing=='mycollections'" />
			</div>
		</div>
		<!-- We want the standards page to always be showing, because once we've loaded Satchel in the iframe there, we want it to stay loaded, wherever the user last left it -->
		<StandardsHomeSatchel v-show="welcome_section_showing=='standards'" />
	</div>
</div>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import HomeHome from './../home/HomeHome'
import FamilyHome from './../home/FamilyHome'
import StandardsHome from './../standards/StandardsHome'
import StandardsHomeSatchel from './../standards/StandardsHomeSatchel'
import AdminList from './../admin/AdminList'
import CourseIndex from './../collectionlist/CourseIndex'
import FavoritesIndex from './../collectionlist/FavoritesIndex'
import RepositoriesIndex from './../collectionlist/RepositoriesIndex'
import MyCollectionsIndex from './../collectionlist/MyCollectionsIndex'
import PDIndex from './../collectionlist/PDIndex'
import ResourceUsage from './ResourceUsage'

export default {
	components: { HomeHome, FavoritesIndex, CourseIndex, StandardsHome, StandardsHomeSatchel, AdminList, RepositoriesIndex, MyCollectionsIndex, PDIndex, FamilyHome, ResourceUsage },
	props: {
		// note: this signin_method prop is not used here, but it's detected and acted on in LoginView (see also router)
		signin_method: { type: String, required: false, default() { return '' }},
	},
	data() { return {
		time_string: '',
		time_string_colon: true,
		welcome_msg: 'Welcome!',

		classes_initialized: false,

		show_all_announcements: false,

		debug_uuid: U.new_uuid(),

		initial_classes_flavor_shown: false,	// see below

		// these are for the "mini-case-tree"
		case_tree_loaded: false,
		case_framework_identifier: '',
		case_search_terms: '',
	}},
	computed: {
		...mapState(['user_info', 'site_config']),
		...mapGetters(['signed_in', 'studentish_role', 'role']),
		role() { return this.user_info.role },
		index_view_flavor: {
			get() { 
				if (!this.signed_in) return this.$store.state.lst.unsigned_index_view_flavor
				else {
					if (this.welcome_section_showing == 'classes') return this.$store.state.lst.course_index_view_flavor
					else if (this.welcome_section_showing == 'mycollections') return this.$store.state.lst.my_index_view_flavor
					else return this.$store.state.lst.repo_index_view_flavor
				}
			},
			set(val) { 
				this.$store.commit('lst_set', ['index_view_flavor', val]) 
				if (!this.signed_in) this.$store.commit('lst_set', ['unsigned_index_view_flavor', val])
				else {
					if (this.welcome_section_showing == 'classes') this.$store.commit('lst_set', ['course_index_view_flavor', val])
					else if (this.welcome_section_showing == 'mycollections') this.$store.commit('lst_set', ['my_index_view_flavor', val])
					else this.$store.commit('lst_set', ['repo_index_view_flavor', val])
				}
			}
		},
		show_pd() {
			if (this.site_config.show_pd === 'no') return false
			return true
		},
		welcome_section_showing: {
			get() { return this.$store.state.lst.welcome_section_showing },
			set(val) { this.$store.commit('lst_set', ['welcome_section_showing', val]) }
		},

		// START TAB CONFIG BLOCK (SF: added 9.13.24 to support customizeable tabs for ALEX)
		home_tab() {
			const { home: customized_home = {} } = this.site_config.customized_tabs
			const icon = customized_home.icon || (this.role === 'parent' ? 'fa-home-user' : 'fa-home')
			const title = customized_home.title || `${this.site_config.app_name} Home`
			return { icon, title }
		},
		classes_tab() {
			const { classes: customized_classes = {} } = this.site_config.customized_tabs
			const icon = customized_classes.icon || 'fas fa-chalkboard'
			const title = customized_classes.title || 'Courses'
			return { icon, title }
		},
		resourcerepos_tab() {
			const { resourcerepos: customized_resourcerepos = {} } = this.site_config.customized_tabs
			const icon = customized_resourcerepos.icon || 'fas fa-diagram-project'
			const title = customized_resourcerepos.title || this.site_config.resourcerepos_noun
			return { icon, title }
		},
		pd_tab() {
			const { pd: customized_pd = {} } = this.site_config.customized_tabs
			const icon = customized_pd.icon || 'fas fa-user-graduate'
			const title = customized_pd.title || 'Professional Development'
			return { icon, title }
		},
		mycollections_tab() {
			const { mycollections: customized_mycollections = {} } = this.site_config.customized_tabs
			const icon = customized_mycollections.icon || 'fas fa-cubes-stacked'
			const title = customized_mycollections.title || 'My Content Collections'
			return { icon, title }
		},
		standards_tab() {
			const { standards: customized_standards = {} } = this.site_config.customized_tabs
			const icon = customized_standards.icon || 'fas fa-map'
			const title = customized_standards.title || 'Standards'
			return { icon, title }
		},
		admin_tab() {
			const { admin: customized_admin = {} } = this.site_config.customized_tabs
			const icon = customized_admin.icon || 'fas fa-cog'
			const title = customized_admin.title || 'Site Admin Tools'
			return { icon, title }
		},
		// END TAB CONFIG BLOCK

		main_tabs_to_include() {
			if (this.site_config.main_tabs_to_include != 'all') return this.site_config.main_tabs_to_include
			return ['home', 'classes', 'resourcerepos', 'mycollections', 'standards']
		},
	},
	created() {
		// console.log('creating WelcomeView ' + this.debug_uuid)
		this.calculate_time_string();
		setInterval(()=>{
			this.calculate_time_string();
		}, 1000)

		// for the courses tab, we normally default to favorites; but if the user doesn't have any sis_classes or added_my_courses, always start with the index
		if (!this.initial_classes_flavor_shown && this.$store.state.sis_classes.length == 0 && this.$store.state.added_my_courses.length == 0) {
			this.$store.commit('lst_set', ['course_index_view_flavor', 'lpindex'])
			this.initial_classes_flavor_shown = true
		}
	},
	mounted() {
		vapp.welcome_view = this
	},
	watch: {
		'$route.path': {immediate: true, handler(val) {
			// when the route changes, change welcome_section_showing accordingly
			let s
			// if (this.$route.path.indexOf('home') == 1) s = this.role === 'parent' ? 'family_home' : 'home'
			if (this.$route.path.indexOf('courses') == 1) s = 'classes'
			else if (this.$route.path.indexOf('standards') == 1) s = 'standards'
			else if (this.$route.path.indexOf('admin') == 1) s = 'admin'
			else if (this.$route.path.indexOf('repos') == 1) s = 'resourcerepos'
			else if (this.$route.path.indexOf('mycollections') == 1) s = 'mycollections'
			else if (this.$route.path.indexOf('pd') == 1) s = 'pd'
			// If user is not superuser but has been granted accesss to resource report, we need a non-admin route
			else if (this.$route.path.indexOf('resource_usage') == 1 && this.$route.path.indexOf('admin') == -1) s = 'resource_usage'

			// default: go to home
			else {
				// if we're explicitly sent to the home route and if we're showing the home page in a dialog, go to the first tab and show the home dialog
				if (this.site_config.show_pop_up) {
					s = this.site_config.main_tabs_to_include[0]
					if (this.$route.path.indexOf('home') == 1) vapp.show_home_dialog = true
				} else {
					s = this.role === 'parent' ? 'family_home' : 'home'
				}
			}
			
			this.welcome_section_showing = s
		}},
	},
	methods: {
		// note that tab_showing here mostly mirrors what we do in MiniNav
		tab_showing(id) {
			// some collection types are never viewable to users that aren't signed in, or to studentish users
			if (id == 'pd' && (!this.signed_in || this.studentish_role)) return false
			if (id == 'mycollections') {
				// for mycollections, we have a show_mycollections_when_not_signed_in flag; if yes, show the tab when not signed in
				if (this.site_config.show_mycollections_when_not_signed_in == 'yes' && !this.signed_in) return true
				// else don't show the tab if not signed in
				if (!this.signed_in || this.studentish_role) return false
			}
			// if main_tabs_to_include is 'all', include everything at this point
			if (this.site_config.main_tabs_to_include == 'all') return true
			// otherwise honor the array stored in main_tabs_to_include
			return this.site_config.main_tabs_to_include.includes(id)
		},
		switch_welcome_section(s) {
			// to switch the welcome section, just go to the corresponding route; the route.path watcher will set welcome_section_showing accordingly
			if (s == 'home') this.go_to_route('home')
			else if (s == 'classes') this.go_to_route('courses')
			else if (s == 'standards') this.go_to_route('standards')
			// else if (s == 'resourcerepo') this.go_to_route('trl')
			else if (s == 'admin') this.go_to_route('admin')
			else if (s == 'resourcerepos') this.go_to_route('repos')
			else if (s == 'mycollections') this.go_to_route('mycollections')
			else if (s == 'resource_usage') this.go_to_route('resource_usage')
			else if (s == 'pd') this.go_to_route('pd')
		},

		go_to_route(new_route, event) {
			if (!empty(event) && !empty(event.target)) $(event.target).closest('button').blur()

			new_route = '/' + new_route
			if (this.$route.path == new_route) return

			this.$router.push({ path: new_route })
		},

		// calculate_time_string() {
		// 	// changing this screws up the spanish translation
		// 	// this.time_string_colon = !this.time_string_colon
		// 	let d = this.$store.state.now_date_obj

		// 	let hour = d.getHours()

		// 	if (hour > 2 && hour < 12) this.time_of_day = 'morning'
		// 	else if (hour < 18) this.time_of_day = 'afternoon'
		// 	else this.time_of_day = 'evening'

		// 	hour = hour % 12
		// 	if (hour == 0) hour = 12

		// 	let minute = d.getMinutes()
		// 	if (minute < 10) minute = '0' + minute

		// 	// Thu Jan 29&nbsp;&nbsp;10:50
		// 	this.time_string = sr('it’s&nbsp;$4<span style="$5">:</span>$6&nbsp;on&nbsp;$1, $2 $3',
		// 		['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][d.getDay()],
		// 		['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][d.getMonth()],
		// 		// ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()],
		// 		d.getDate(),
		// 		hour,
		// 		this.time_string_colon ? '' : 'visibility:hidden',
		// 		minute,
		// 	)
		// },

		calculate_time_string() {
			// this.time_string_colon = !this.time_string_colon
			let d = new Date()

			let hour = d.getHours()

			let time_of_day
			if (hour > 2 && hour < 12) time_of_day = 'morning'
			else if (hour < 18) time_of_day = 'afternoon'
			else time_of_day = 'evening'

			this.welcome_msg = 'Good ' + time_of_day
			if (this.user_info.first_name) this.welcome_msg += ', ' + this.user_info.first_name
			this.welcome_msg += '!'

			hour = hour % 12
			if (hour == 0) hour = 12

			let minute = d.getMinutes()
			if (minute < 10) minute = '0' + minute

			// Thu Jan 29&nbsp;&nbsp;10:50
			this.time_string = sr('It’s&nbsp;$4<span style="$5">:</span>$6&nbsp;on&nbsp;$1, $2 $3',
				['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][d.getDay()],
				['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][d.getMonth()],
				// ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][d.getMonth()],
				d.getDate(),
				hour,
				this.time_string_colon ? '' : 'visibility:hidden',
				minute,
			)
		},

		// PW: not currently used; do make this work properly we might need to switch the "CourseIndex" to v-show instead of v-if
		search_lps(search_string) {
			// this.index_view_flavor = 'lpindex'
			this.switch_welcome_section('classes')
			this.$nextTick(()=>{
				console.log('search_lps: ' + search_string)
				this.$refs.lp_index_component.external_search(search_string)
			})
		},

	}
}
</script>

<style lang="scss">
.k-main-welcome {
	text-align:center;

	.k-main-welcome__question {
		// margin:5vh 0 1vh 0;
		font-size: 35px;
		line-height:45px;
		font-weight:bold;
		color:$v-doe-light-orange;
	}

	.k-main-welcome__time-date {
		font-family:$display-font;
		font-weight:900;
		font-size:24px;
		// margin-bottom:4vh;
	}

	.k-main-welcome--nav-btns {
		margin-top:2vh;
		// margin-bottom:-5px;
		position:relative;
		z-index:2;

		.k-main-welcome--nav-btn {
			// display:block;
			width:120px;
			height:80px;
			padding-top:5px;
			border-color:$page-background!important;
			border-width:3px 3px 0 3px!important;
			border-style:solid;
			border-radius:20px 20px 0 0;

			// -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)!important;
			// box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)!important;
			-webkit-box-shadow: none!important;
			box-shadow: none!important;

			margin:0 10px;

			.v-icon {
				color:$page-background;
				font-size:54px;
			}
		}

		.k-main-welcome--nav-btn-current {
			border-color:transparent!important;
			background-color:$page-background!important;
		}
	}

	.k-main-welcome--pages {
		position:relative;
	}
}
</style>
