var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"k-mini-nav"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{staticClass:"k-mini-nav-btn-outer"},[_c('div',{directives:[{name:"visible",rawName:"v-visible",value:(false),expression:"false"}],staticClass:"k-mini-nav-btn-current-indicator"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fas fa-right")])],1),_c('v-btn',_vm._g({staticClass:"k-mini-nav-non-current-btn elevation-0",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.go_to_home('home', $event)}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.home_tab.icon))])],1)],1)]}}])},[_vm._v(_vm._s(_vm.home_tab.title))]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab_enabled('classes')),expression:"tab_enabled('classes')"}],staticClass:"k-mini-nav-btn-outer"},[_c('div',{directives:[{name:"visible",rawName:"v-visible",value:(_vm.mininav_tab_showing('classes')),expression:"mininav_tab_showing('classes')"}],staticClass:"k-mini-nav-btn-current-indicator"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fas fa-right")])],1),_c('v-btn',_vm._g({class:(!_vm.mininav_tab_showing('classes'))?'k-mini-nav-non-current-btn elevation-0':'',attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.go_to_home('classes', $event)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.classes_tab.icon))])],1)],1)]}}])},[_vm._v(_vm._s(_vm.classes_tab.title))]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab_enabled('resourcerepos')),expression:"tab_enabled('resourcerepos')"}],staticClass:"k-mini-nav-btn-outer"},[_c('div',{directives:[{name:"visible",rawName:"v-visible",value:(_vm.mininav_tab_showing('resourcerepos')),expression:"mininav_tab_showing('resourcerepos')"}],staticClass:"k-mini-nav-btn-current-indicator"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fas fa-right")])],1),_c('v-btn',_vm._g({class:(!_vm.mininav_tab_showing('resourcerepos'))?'k-mini-nav-non-current-btn elevation-0':'',attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.go_to_home('resourcerepos', $event)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.resourcerepos_tab.icon))])],1)],1)]}}])},[_vm._v(_vm._s(_vm.resourcerepos_tab.title))]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tab_enabled('mycollections')),expression:"tab_enabled('mycollections')"}],staticClass:"k-mini-nav-btn-outer"},[_c('div',{directives:[{name:"visible",rawName:"v-visible",value:(_vm.mininav_tab_showing('mycollections')),expression:"mininav_tab_showing('mycollections')"}],staticClass:"k-mini-nav-btn-current-indicator"},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fas fa-right")])],1),_c('v-btn',_vm._g({class:(!_vm.mininav_tab_showing('mycollections'))?'k-mini-nav-non-current-btn elevation-0':'',attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.go_to_home('mycollections', $event)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mycollections_tab.icon))])],1)],1)]}}])},[_vm._v(_vm._s(_vm.mycollections_tab.title))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }