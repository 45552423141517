// Copyright 2023, Common Good Learning Tools LLC
import { mapState, mapGetters } from 'vuex'

export default {
	data() { return {
	}},
	computed: {
		...mapState(['site_config', 'my_activities']),
		...mapGetters(['signed_in']),
		// find the ancestor CollectionUnit component where this lesson was launched from (might be null)
		collection_unit_component() { return U.find_ancestor_vue_component(this, 'CollectionUnit') },
		allow_create_activity_from_resource() {
			// we currently allow creating an activity from a resource in the lesson if we're signed in and embedded in a unit that has the assignments tab
			if (!this.signed_in) return false
			if (!this.collection_unit_component) return false
			if (!this.collection_unit_component.show_assignments_tab) return false
			return true
		},

		resource_option_overrides() { return (item) => {
			let o = {
				// don't allow duplicating from here
				duplicate: false,
			}

			// set create_sparkl_from_resource appropriately
			o.create_sparkl_from_resource = (this.allow_create_activity_from_resource && item.activity_type != 'lesson' && item.type != 'sparkl_bank')

			// you can remove resources if we're in the context of the editor, but not if we're in the context of the viewer
			if (!empty(this.original_lesson)) {
				// also, if the user created a sparkl that is explicitly tied to the lesson, don't allow that to be removed
				if (item.url == this.edited_lesson.sparkl_activity_id) o.remove = false
				else o.remove = true

			} else o.remove = false

			// for linked lessons, don't allow editing or copying for your use
			if (item.activity_type == 'lesson') {
				o.edit = false
				// we'll deal with this later if someone asks about it
				o.copy_item_for_my_use = false
			}

			// console.warn('resource_option_overrides: '+ JSON.stringify(o))
			return o
		}},
	},
	watch: {
	},
	methods: {
		section_text(component) {
			if (empty(component.lc_content)) return ''
	
			// render latex
			let content = U.render_latex(component.lc_content)

			// make sure all a tags open in a new window (an a tag might get two target tags, but that's OK)
			content = content.replace(/<a /g, '<a target="_blank" ')

			let mdc = this.master_component(component)
			if (empty(mdc)) return content

			// regardless of lc_text_format, if the content includes a block tag, just return the plain content
			if (content.search(/<(p|ul|li|div|table)\b/) > -1) {
				return content
			}

			if (empty(mdc?.lc_text_format) || mdc.lc_text_format == 'paragraphs') {
				// for 'paragraphs' style, or if we don't have a master component or lc_text_format is empty, just return the plain content
				return content

			} else if (mdc.lc_text_format == 'line') {
				// for single-line style (e.g. questions), wrap in a paragraph
				return `<p>${content}</p>`

			} else {
				// else assume it's a list (could be, e.g. 'list' or 'list_plain'; use unordered list
				let lines = content.split('<br>')
				let s = `<ul>`
				for (let line of lines) s += `<li>${line}</li>`
				s += `</ul>`
				return s
			}
		},

		get_resource_collection_item(resource) {
			// this has to work for lessons or resources...
			let resource_id, type
			if (typeof(resource) == 'object') {
				if (!empty(resource.lesson_id)) {
					type = 'lesson'
					resource_id = resource.lesson_id
				} else {
					type = 'resource'
					resource_id = resource.resource_id
				}
			
			} else {
				// lesson id's are integers
				type = (!isNaN(resource * 1)) ? 'lesson' : 'resource'
				resource_id = resource
			}

			if (type == 'resource') {
				let rci = this.$refs.resource_collection_item.find(x=>x.item.resource_id==resource_id)
				// let rci = this.$refs.resource_collection_item.find(x=>x.item.resource_id==resource_id&&$(x.$el).is(':visible'))
				if (!rci) console.error('couldn’t get rci for ', resource)
				return rci
			} else {
				let rci = this.$refs.resource_collection_item_linked_lesson.find(x=>x.item.resource_id==resource_id)
				// let rci = this.$refs.resource_collection_item_linked_lesson.find(x=>x.item.resource_id==resource_id&&$(x.$el).is(':visible'))
				if (!rci) console.error('couldn’t get rci for ', resource)
				return rci
			}
		},

		// this is called when the user clicks on a resource in a component
		show_resource_quick_look(resource_id, resource_link_id) {
			let rci = this.get_resource_collection_item(resource_id)
			if (rci) {
				rci.card_clicked()
			}

			// highlight the clicked link object
			$(this.$el).find('[data-resource-link-id]').removeClass('k-last-lesson-quick-look-resource')
			$(this.$el).find(`[data-resource-link-id=${resource_link_id}]`).addClass('k-last-lesson-quick-look-resource')
		},

		linked_lesson_opened() {
			// code in App.vue depends on having a reference to the currently-open lesson_view_component, so if we open a linked lesson,
			// save the current value of vapp.lesson_view_component so we can restore it when we close the new lesson viewer
			this.previous_lesson_view_component = vapp.lesson_view_component
		},

		linked_lesson_closed() {
			vapp.lesson_view_component = this.previous_lesson_view_component
		},

		// find and open the lesson sparkl activity
		open_lesson_sparkl_activity() {
			console.warn('open_lesson_sparkl_activity')
			let lesson = this.edited_lesson || this.lesson	// so this works from LessonView or LessonEditorEnhanced
			let r = lesson.resources.find(x=>x.type == 'sparkl' && x.url == lesson.sparkl_activity_id)

			if (!r) {
				this.$alert(`The ${this.site_config.sparkl_app_name} Activity for this lesson appears to have been removed from the lesson resources.`)
				return
			}
			
			this.get_resource_collection_item(r)?.show_sparkl('edit')
		},

		create_activity_from_resource(resource, flag) {
			if (this.$store.getters.simulating_user) { this.$alert('You are not allowed to do this while simulating another user.'); return; }

			// if the resource is already sparkl activity, we just want to assign it...
			if (resource.type == 'sparkl') {
				this.assign_sparkl_activity(resource)
				return
			}
			
			this.$prompt({
				title: 'Use Resource in Student Activity',
				text: `To use this ${resource.type_label(true)} in a Student Activity, start by entering a student-facing description for the linked resource below, then click <nobr><b class="deep-orange--text text--darken-4">ADD TO EXISTING ACTIVITY</b></nobr> or <nobr><b class="blue--text text--darken-4">CREATE NEW ACTIVITY</b>.`,
				initialValue: resource.description,
				disableForEmptyValue: true,

				extraBtnText: 'Add to Existing Activity',
				extraBtnIconAfter: 'fas fa-circle-arrow-right',
				extraBtnColor: 'deep-orange darken-4',

				acceptText: 'Create New Activity',
				acceptIconAfter: 'fas fa-circle-arrow-right',
				acceptColor: 'blue darken-4',

				dialogMaxWidth: 700,
			}).then((rv) => {
				// if rv is a string, it means they clicked to add new
				if (typeof(rv) == 'string') {
					rv = rv.trim()
					if (empty(rv)) return

					// proceed to create a new activity from the resource
					this.complete_create_activity_from_resource(resource, rv, null)

				// otherwise it means that they clicked to add to existing
				} else {
					rv = rv[0].trim()
					if (empty(rv)) return

					// proceed to copy the data for a new sparkl exercise part to the clipboard
					this.copy_sparkl_part_to_clipboard(resource, rv)
				}
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		copy_sparkl_part_to_clipboard(resource, link_description) {
			this.$confirm({
				title: 'Add Resource to Existing Activity',
				text: `Click the button below to copy to your clipboard a ${this.site_config.sparkl_app_name} exercise part linking to this ${resource.type_label(true)}. You can then open any activity and paste the resource into the activity, by either:<ul><li>Clicking ADD ANOTHER EXERCISE, then choosing “Import questions or exercises” > “<b>Paste</b> an exercise from your clipboard” or</li><li>Clicking the “kebab” (<i class="fas fa-ellipsis-v"></i>) menu for an existing exercise and choosing “Paste exercise part from clipboard”</li></ul>`,
				dialogMaxWidth: 540,
				acceptText: 'Copy Exercise Part to Clipboard',
			}).then(() => {
				// copy to the clipboard the code to paste into an activity
				let s = resource.get_sparkl_activity_html(link_description, 'exercise_part')
				U.copy_to_clipboard(s)
				this.inform(`${this.site_config.sparkl_app_name} exercise part copied to clipboard.`)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		complete_create_activity_from_resource(resource, link_description, existing_activity_id) {
			// console.warn('complete_create_activity_from_resource', resource, link_description, existing_activity_id)
			if (resource.type == 'sparkl') {
				this.$alert({
					title: 'Assign Activity',
					text: `The ${this.site_config.sparkl_app_name} editor has been opened for your activity. You can now enhance the activity if you wish (e.g. by adding instructions, questions, and/or additional content), then click the CLOSE ACTIVITY button at the top of the window, and assign the activity to your students.`
				})
			} else {
				this.$alert({
					title: 'Create New Activity',
					text: `The ${this.site_config.sparkl_app_name} activity editor has been opened, with this ${resource.type_label(true)} added to the activity. You can now enhance the activity if you wish (e.g. by adding instructions, questions, and/or additional content), then click the CLOSE ACTIVITY button at the top of the window, and assign the activity to your students.`
				})
			}

			if (this.collection_unit_component) {
				// note that the 'type' here is 'activity' (*not* 'sparkl')
				this.collection_unit_component.create_activity_from_resource({type:'activity', starting_resource:resource, link_description: link_description, existing_activity_id: existing_activity_id})
			} else this.$alert('Error 38739 in LessonEnhancedMixin')	// shouldn't happen
			// TODO: could allow for adding to sandbox

			// if we're in the lesson editor...
			if (this.$options.name == 'LessonEditor') {
				// first save and close, 
				this.save_and_close()
				// then find the lesson view if it's showing and emit to close
				let lesson_component = U.find_ancestor_vue_component(this, 'DirectivesListItem')
				if (lesson_component) {
					setTimeout(x=>lesson_component.$emit('close_lesson'), 80)
				}
			} else {
				// else we just have to close this component
				this.$emit('close_lesson')
			}
		},

		assign_sparkl_activity(resource) {
			// first see if the activity is already assigned
			if (this.my_activities.find(x=>x.resource_id == resource.resource_id)) {
				this.$alert('You have already assigned this Sparkl activity to your students.')
				// TODO: offer to create a different assignment??
				return
			}
			this.complete_create_activity_from_resource(resource, '', resource.url)
		},
	}
}